const WORDS_LIBRARY = {
  atingiu: "atingiram",
  mês: "meses",
};

export function formatDate(dateString) {
  const [datePart, timePart] = dateString.split(' ')
  const [year, month, day] = datePart.split('-')
  const [hours, minutes, seconds] = timePart.split(':')

  // Formata no formato desejado (dd/MM/yyyy HH:mm:ss)
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
}

export const lightOrDark = (color) => {
  if (color.length < 4) return "light";

  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "light";
  } else {
    return "dark";
  }
};

export const formatter = {
  number: (number) => customFormatter({ number, currency: false }),
  currency: (number) => customFormatter({ number, currency: true }),
  date: (date, dateStyle, showTime) =>
    customFormatter({ date, dateStyle, showTime }),
};

function customFormatter({
  number = null,
  currency = false,
  date = null,
  dateStyle = "long",
  showTime = true,
}) {
  const formatter = date
    ? new Intl.DateTimeFormat("pt-BR", {
        dateStyle,
        ...(showTime && { timeStyle: "short" }),
      }).format(new Date(date))
    : new Intl.NumberFormat("pt-BR", {
        style: currency ? "currency" : "decimal",
        currency: "BRL",
      }).format(Number(number || 0));

  return formatter;
}

export function uid() {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < 20; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const pluralize = (word, count) => {
  if (word) {
    return Number(count) === 0 || Number(count) > 1
      ? WORDS_LIBRARY[word] || word + "s"
      : word;
  }
};

export function parseUserAgent(userAgent) {
  let device = "Desconhecido";
  let browser = "Desconhecido";
  let browserVersion = "-";

  if (!userAgent) return { device, browser, browserVersion };

  const userAgentLowerCase = userAgent.toLowerCase();
  const DEVICES = {
    iphone: "iPhone",
    ipad: "iPad",
    ipod: "iPod",
    android: "Android",
    windows: "Windows",
    macintosh: "MacOS",
  };

  const BROWSERS = {
    chrome: "Chrome",
    crios: "Chrome",
    safari: "Safari",
    firefox: "Firefox",
    edge: "Edge",
  };

  for (const key in DEVICES) {
    if (userAgentLowerCase.includes(key)) {
      device = DEVICES[key];
      break;
    }
  }

  for (const key in BROWSERS) {
    if (userAgentLowerCase.includes(key)) {
      browser = BROWSERS[key];
      const match = userAgentLowerCase.match(
        new RegExp(`${key}\\/(\\d+([\\._]\\d+)+)`, "i")
      );
      if (match) {
        browserVersion = match[1];
      }
      break;
    }
  }

  return { device, browser, browserVersion };
}
