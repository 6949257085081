import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { formatter, lightOrDark } from "../../functions";
import { Colors } from "../../styles/Colors";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { Text } from "../layout/components/text";
import { Alert, Selector, Stock, TextArea, TextField } from "../ui";
import { AddButton, RemoveButton } from "../ui/components/buttons";
import Dropzone from "../ui/components/upload/Dropzone";
import ImageList from "../ui/components/upload/ImageList";
import Confirm from "../utils/Confirm";
import { useFetch } from "../../hooks/useFetch";
import ProductPrices from "./product-prices";

const DottedLine = ({ leftData, rightData }) => {
  if (!leftData || !rightData) return <></>;

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: 4,
      }}
    >
      <Text detail style={{ whiteSpace: "nowrap" }}>
        {leftData}
      </Text>
      <Box
        style={{
          borderBottom: `1px dotted #ccc`,
          width: "100%",
          height: "84%",
        }}
      />
      <Text detail>{rightData}</Text>
    </Box>
  );
};

const EditProduct = (props) => {
  const { pathname } = props.location;
  const arrayPath = pathname.split("/");
  const _id = arrayPath[arrayPath.length - 1];

  const token = localStorage.getItem("token");
  const headers = {
    headers: { Authorization: "bearer " + token },
  };

  const descriptionRef = useRef(null);
  const priceAndActionRef = useRef(null);
  const stockRef = useRef(null);

  const [data, setData] = useState({
    images: [],
  });
  const [productPrices, setProductPrices] = useState({});
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [customAlert, setCustomAlert] = useState({
    open: false,
    title: "",
    msg: "",
  });
  const [enableStockAlert, setEnableStockAlert] = useState(
    typeof data?.stockAlert === "number"
  );
  const [showStockModal, setShowStockModal] = useState(false);
  const [selectorModal, setSelectorModal] = useState({
    open: false,
    data: [],
    callback: () => {},
  });
  // const [images, setImages] = useState([]);
  // const [urlImg, setUrlImg] = useState(null);
  // const [secondaryImage, setSecondaryImage] = useState(null);

  const { data: productData, isLoading } = useFetch({
    url: `/v2/admin/products/${_id}`,
  });
  const { data: discounts } = useFetch({ url: "/listDiscount" });
  const { data: categories } = useFetch({ url: "/v2/admin/categories" });
  const { data: features } = useFetch({ url: "/feature" });
  const { data: units } = useFetch({ url: "/unit" });
  const { data: styles } = useFetch({ url: "/style" });
  const { data: ambients } = useFetch({ url: "/ambient" });

  const onCheckChange = (name) => (event) => {
    if (!data?.price || data?.price === "0" || !data.urlImg) {
      alert(
        "Para exibir o produto no site ele deve possuir uma imagem principal e um preço."
      );
      return;
    }

    if (name === "enableStockAlert") {
      setEnableStockAlert(event.target.checked);
      return;
    }

    setData({
      ...data,
      [name]: event.target.checked,
    });
  };

  const onSave = async () => {
    if (!Boolean(data?.price) || !data.urlImg) {
      return alert(
        "Para exibir o produto no site ele deve possuir uma imagem principal e um preço."
      );
    }

    // data.images = images;
    data.id_john = data?.id_sap;
    data.discounts = productPrices;

    if (!data._id) {
      return newProduct();
    }

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/product/${data._id}`,
        data,
        headers
      )
      .then((response) => {
        setCustomAlert({ open: true, msg: response.data.message });
        window.location.reload();
      })
      .catch((error) => {
        setCustomAlert({ open: true, msg: "Houve um problema" });
      });
  };

  const newProduct = async () => {
    //   const { data } = state;
    //   setState({
    //     saving: true,
    //     loading: true,
    //   });
    axios
      .post(`${process.env.REACT_APP_API_URL}/addProduct`, data, headers)
      .then((response) => {
        console.log("---> ", response.data);
        setCustomAlert({ msg: response.data.message });
        props.history.push(`/app/product/${response?.data?.data?._id}`);
      })
      .catch((error) => {
        console.log(error);
        alert("Erro ao tentar criar um novo produto.");
      });
  };

  const onChangeStock = (e) => {
    const { name, value } = e.target;
    const { stock } = data;

    const newStock = stock.map((item) => {
      if (item.id_unit !== name) return item;
      return { ...item, stock: Number(value) };
    });

    setData({ ...data, stock: newStock });
  };

  const onChange = (e) => {
    let { name, value } = e.target;
    if (["width", "length", "height", "price", "discount"].includes(name)) {
      value = Number(value);
    }
    setData({ ...data, [name]: value });
  };

  const updateCategory = async ({ removeFrom, addTo }) => {
    await axios.patch(
      `${process.env.REACT_APP_API_URL}/v2/admin/product-categories/${addTo}?removeFrom=${removeFrom}&productId=${data._id}`,
      {},
      headers
    );
  };

  const handleChangeCategory = ({ target }) => {
    const { value } = target;
    updateCategory({ removeFrom: data.category, addTo: value });
    setData({ ...data, category: value });
  };

  const handleRemoveProductFromAmbient = async (ambient) => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/v2/admin/product-ambients/${ambient}?productId=${data._id}`,
      headers
    );
  };

  const handlePushProductIntoAmbient = async (ambient) => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/v2/admin/product-ambients/${ambient}?productId=${data._id}`,
      {},
      headers
    );
  };

  // ************
  const onStockAdd = async (data) => {
    //   let { units_data = [] } = state;
    //   let { stock } = state.data;
    //   const { stock: qtd, unit } = data;
    //   let unit_item = await units_data.filter((item) => item._id == unit)[0];
    //   const newStock = [
    //     ...stock.filter((item) => item.id_unit !== unit_item.id_unit),
    //     {
    //       stock: Number(qtd),
    //       id_unit: unit,
    //       unit_id: unit_item.unit_id,
    //       urlSeo: unit_item.urlSeo,
    //     },
    //   ];
    //   setState({
    //     data: {
    //       ...state.data,
    //       stock: newStock,
    //     },
    //     showStockModal: false,
    //   });
  };

  const onAddColor = () => {
    let { colors } = data;
    colors.push({ color: "#FFF" });
    setData({ ...data, colors });
  };

  const onRemoveColor = (index) => {
    let { colors } = data;
    colors.splice(index, 1);
    setData({ ...data, colors });
  };

  const onChangeColor = (e, index) => {
    let { colors } = data;
    colors[index].color = e.target.value;
    setData({ ...data, colors });
  };

  const onAddFeature = (item) => {
    let { features } = data;
    features.push({ title: item.label });
    setData({ ...data, features });
  };

  const removeFeature = (index) => {
    let { features } = data;
    features.splice(index, 1);
    setData({ ...data, features });
  };

  const onAddUnit = async (item) => {
    let { units } = data;
    units.push({ title: item.value });
    setData({ ...data, units });
  };

  const removeUnit = (index) => {
    let { units } = data;
    units.splice(index, 1);
    setData({ ...data, units });
  };

  const onAddAmbient = (item) => {
    let { ambients } = data;
    ambients.push({ title: item.label });
    setData({ ...data, ambients });
    handlePushProductIntoAmbient(item.label);
  };

  const removeAmbient = (index) => {
    let { ambients } = data;
    handleRemoveProductFromAmbient(ambients[index].title);
    ambients.splice(index, 1);
    setData({ ...data, ambients });
  };

  const onAddStyle = (item) => {
    let { styles } = data;
    styles.push({ title: item.label });
    setData({ ...data, styles });
  };

  const removeStyle = (index) => {
    let { styles } = data;
    styles.splice(index, 1);
    setData({ ...data, styles });
  };

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const onDropFiles = async (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      id: getRandomInt(1, 999),
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    uploadedFiles.forEach(processUpload);
  };

  const processUpload = async (uploadedFile) => {
    console.log({ uploadedFile });
    setData((prev) => ({
      ...prev,
      images: [uploadedFile].concat(prev?.images || []),
    }));

    // setData({
    //   ...data,
    //   images: [data?.images, uploadedFile],
    // });

    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);
    axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, data, headers)
      .then((response) => {
        const { data } = response;
        console.log({ data });
        updateFile(uploadedFile.id, data);
      })
      .catch((error) => console.log("error", error));
  };

  const updateFile = (id, item) => {
    setData((prevData) => {
      const newImages =
        prevData?.images && prevData?.images.length > 0
          ? prevData?.images.map((image) => {
              if (image.id !== id) return image;
              return { ...image, ...item, uploaded: true };
            })
          : [{ ...item, uploaded: true }];

      return {
        ...prevData,
        images: newImages,
      };
    });
  };

  const onDeleteImage = (id) => {
    setData((prev) => ({
      ...prev,
      images: prev.images.filter((item) => item.id !== id),
    }));
  };

  const onDeletePress = async () => {
    const { _id } = data;

    axios
      .delete(`${process.env.REACT_APP_API_URL}/product/${_id}`, headers)
      .then((response) => {
        props.history.push(`/app/products/`);
      });
  };

  // const onDropPdf = (files) => {
  //   const uploadedFiles = files.map((file) => ({
  //     file,
  //     id: uniqueId(),
  //     name: file.name,
  //     preview: URL.createObjectURL(file),
  //     progress: 0,
  //     uploaded: false,
  //     error: false,
  //     url: null,
  //   }));
  //   uploadedFiles.forEach(processUploadPdf, true);
  // };

  const getUnitBySeo = (urlSeo) => {
    return units.filter((item) => item.urlSeo === urlSeo)[0];
  };

  // const processUploadPdf = async (uploadedFile, mainImg = true) => {
  //   const data = new FormData();
  //   data.append("file", uploadedFile.file, uploadedFile.name);

  //   axios
  //     .post(`${process.env.REACT_APP_API_URL}/upload`, data, headers)
  //     .then((response) => {
  //       const { data } = response;
  //       setData({ ...data, manual: data.url });
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  const changePriceList = (e) => {
    setData({
      ...data,
      priceListId: e.target.value,
    });
  };

  const calculatePrices = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/admin/products/calculate-prices?price=${data.price}&discount=${data.discount}&priceListId=${data.priceListId._id}`,
        headers
      )
      .then((res) => {
        setProductPrices(res.data);
      })
      .catch(() => {
        setProductPrices({});
        alert("Houve um problema ao calcular os preços");
      });
  };

  useEffect(() => {
    if (!data.priceListId?.name || !data.price) return;
    const timer = setTimeout(async () => {
      await calculatePrices();
    }, [2000]);

    return () => clearTimeout(timer);
  }, [data.priceListId?.name, data.price, data.discount]);

  useEffect(() => {
    if (!productData?._id) return;
    setData(productData);
  }, [productData]);

  const imageContainerHeight = descriptionRef?.current?.clientHeight || 0;
  const stockContainerHeight = stockRef?.current?.clientHeight || 300;
  const discountContainerHeight = priceAndActionRef?.current?.clientHeight || 0;

  const discountAmount = Math.ceil(data?.price * (data.discount / 100));
  const finalPrice = Math.floor(data?.price - discountAmount);

  if (isLoading) return <p>Carregando...</p>;

  return (
    <>
      {customAlert.open && (
        <Alert open={true} title={customAlert.title} msg={customAlert.msg} />
      )}
      <Confirm
        open={isConfirmOpen}
        title="Exclusão de Produto"
        text="Deseja realmente excluir este produto?"
        onDeletePress={onDeletePress}
        onClose={() => setIsConfirmOpen(false)}
      />
      <ContentHeader
        title={data?.name || "Novo Produto"}
        subtitle={
          data?.lastUpdatedBy?.name
            ? `Atualizado por ${data.lastUpdatedBy.name} em ${formatter.date(
                data?.updatedAt,
                "short"
              )}`
            : ``
        }
        buttons={[
          { text: "Salvar", onClick: onSave },
          {
            text: "Excluir",
            destructive: true,
            onClick: () => setIsConfirmOpen(true),
          },
        ]}
      />
      <Box style={{ display: "flex", gap: 24, flex: 1 }}>
        <Box ref={descriptionRef} style={{ display: "flex", flex: 1 }}>
          <ContentContainer label="Nome e descrição" fullWidth>
            <TextField
              value={data?.name}
              onChange={onChange}
              name="name"
              placeholder="Digite o nome do produto"
              title="Nome do Produto"
            />
            <TextArea
              value={data?.description}
              name="description"
              title="Descrição"
              onChange={onChange}
            />
            <TextArea
              value={data?.technicalDescription}
              name="technicalDescription"
              title="Descrição Técnica"
              onChange={onChange}
            />
          </ContentContainer>
        </Box>
        <ContentContainer
          label="Imagens"
          style={{ height: imageContainerHeight }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              maxWidth: 300,
              flexDirection: "column",
              margin: 15,
            }}
          >
            {data?.urlImg && (
              <div style={{ marginBottom: 15 }}>
                <Text detail>Imagem principal</Text>
                <img
                  src={data?.urlImg || ""}
                  style={{ width: "100%", borderRadius: 8 }}
                  alt="main-image"
                />
              </div>
            )}
            {data?.secondaryImage && (
              <div style={{ marginBottom: 15 }}>
                <Text detail>Imagem secundária</Text>
                <img
                  src={data?.secondaryImage || ""}
                  style={{ width: "100%", borderRadius: 8 }}
                  alt="secondary-image"
                />
              </div>
            )}
            <Dropzone
              txt="Arraste aqui as imagens do produto ou clique para selecionar os arquivos. Imagem Padrão dos produtos (800px x 610px) ou proporcional."
              onDrop={onDropFiles}
            />
            {/* <ImageList
              data={data.images}
              onDeleteImage={onDeleteImage}
              setImageDefault={(urlImg) => {
                // setState({ data: { ...data, urlImg } });
              }}
              setSecondaryImage={
                (secondaryImage) => {}
                // setState({ data: { ...data, secondaryImage } })
              }
            /> */}
            <ImageList
              data={data.images || []}
              onDeleteImage={onDeleteImage}
              setImageDefault={(urlImg) => setData({ ...data, urlImg })}
              setSecondaryImage={
                (secondaryImage) => setData({ ...data, secondaryImage })
                // setSecondaryImage(secondaryImage)
              }
            />
          </div>
        </ContentContainer>
      </Box>

      <Box style={{ display: "flex", gap: 24 }}>
        <Box
          style={{ display: "flex", flexDirection: "column", gap: 24 }}
          ref={priceAndActionRef}
        >
          <ContentContainer label="Preço" style={{ width: 400 }}>
            <Text
              style={{
                textAlign: "center",
                color: Colors.darkGreenAlt,
                fontSize: 24,
                fontWeight: "700",
                marginTop: 20,
              }}
            >
              {formatter.currency(finalPrice)}
            </Text>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <DottedLine
                leftData="Valor original"
                rightData={formatter.currency(data?.price)}
              />
              <DottedLine
                leftData="Desconto"
                rightData={formatter.currency(discountAmount)}
              />
            </Box>

            <TextField
              value={data?.price}
              onChange={onChange}
              name="price"
              type="number"
              title="Valor mensal"
              style={{ flex: "unset" }}
            />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <TextField
                value={data?.discount}
                onChange={onChange}
                name="discount"
                type="number"
                title="Porcentagem de desconto"
                style={{ flex: "unset" }}
              />
            </Box>
            <Text detail>
              Todos os valores são arredondados para baixo. Portanto, um produto
              que, com desconto, custaria R$ 82,01 ou R$ 82,99, por exemplo,
              terá seu valor arredondado para R$ 82,00.
            </Text>
          </ContentContainer>
          <ContentContainer label="Ações">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data?.toPartner}
                    onChange={onCheckChange("toPartner")}
                    color="secondary"
                  />
                }
                label={<Text>Para Parceria</Text>}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={data?.driveThru}
                    onChange={onCheckChange("driveThru")}
                    color="secondary"
                  />
                }
                label={<Text>Para Drive Thru</Text>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data?.updateStock}
                    onChange={onCheckChange("updateStock")}
                    color="secondary"
                  />
                }
                label={<Text>Aceitar Atualizações de Estoque</Text>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data?.featured}
                    onChange={onCheckChange("featured")}
                    color="secondary"
                  />
                }
                label={<Text>Destacar Produto</Text>}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableStockAlert}
                    onChange={onCheckChange("enableStockAlert")}
                    color="secondary"
                  />
                }
                label={<Text>Ativar alerta de estoque</Text>}
              />
              {enableStockAlert ? (
                <Box>
                  <TextField
                    value={data?.stockAlert}
                    onChange={onChange}
                    name="stockAlert"
                    style={{ maxWidth: 220 }}
                    title="Número limite"
                  />
                </Box>
              ) : (
                <></>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data?.visible}
                    onChange={onCheckChange("visible")}
                    color="secondary"
                  />
                }
                label={<Text>Exibir no site</Text>}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={data?.alwaysVisible}
                    onChange={onCheckChange("alwaysVisible")}
                    color="secondary"
                  />
                }
                label={<Text>Sempre visível</Text>}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={data?.active}
                    onChange={onCheckChange("active")}
                    color="secondary"
                  />
                }
                label={<Text>Ativo</Text>}
              />
            </div>
          </ContentContainer>
        </Box>
        <ContentContainer
          label="Descontos"
          fullWidth
          style={{ height: discountContainerHeight }}
        >
          <ProductPrices prices={productPrices} />
          <Box
            style={{
              display: "flex",
              alignItems: "flex-end",
              flex: 1,
            }}
          >
            <FormControl style={{ minWidth: 120, flex: 1 }}>
              <InputLabel id="demo-simple-select-label">
                Tabela de desconto
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data?.priceListId?.name || ""}
                onChange={changePriceList}
                renderValue={(selected) => selected}
                disabled={Boolean(!data?.price)}
              >
                {discounts.map((discountTable) => (
                  <MenuItem key={discountTable._id} value={discountTable}>
                    {discountTable.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </ContentContainer>
      </Box>
      <Box style={{ display: "flex", gap: 24, flex: 1 }} ref={stockRef}>
        <ContentContainer label="Estoque" fullWidth>
          <Box
            // ref={stockRef}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 24,
              flex: 1,
            }}
          >
            <TextField
              value={data?.estoque}
              onChange={onChange}
              name="estoque"
              title="São Paulo"
              fullWidth
            />
            {data?.stock?.map((stock, indexStock) => {
              let unit = getUnitBySeo(stock.urlSeo);
              if (unit) {
                return (
                  <React.Fragment key={indexStock + stock.title}>
                    {unit.title !== "São Paulo" && (
                      <TextField
                        value={stock.stock}
                        onChange={onChangeStock}
                        id={stock.id_unit}
                        name={stock.id_unit}
                        title={`${unit.title}`}
                      />
                    )}
                  </React.Fragment>
                );
              }
            })}
            <div
              onClick={() => setShowStockModal(true)}
              style={{
                padding: 7,
                borderWidth: 0.5,
                borderStyle: "dashed",
                cursor: "pointer",
                borderRadius: 5,
                marginBottom: 15,
                fontSize: 12,
              }}
            >
              + Estoque
            </div>
            {showStockModal && (
              <Stock
                units={units?.filter((stock) => stock.unit_id != 8)}
                onClose={() => setShowStockModal(false)}
                onStockAdd={onStockAdd}
              />
            )}
          </Box>
        </ContentContainer>
        <ContentContainer
          label="Características"
          fullWidth
          style={{ maxHeight: stockContainerHeight }}
        >
          <FormControl style={{ minWidth: 120, flex: 1 }}>
            <InputLabel id="category-select-label">Categoria</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={data?.category}
              name="category"
              onChange={handleChangeCategory}
            >
              {categories.map((item) => {
                return (
                  <MenuItem key={`category_${item._id}`} value={item.title}>
                    {item.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <TextField
              value={data?.width}
              onChange={onChange}
              name="width"
              placeholder="Largura"
              title="Largura (cm)"
              style={{ maxWidth: 120 }}
            />
            <div style={{ marginBottom: 15, padding: 10 }}>X</div>
            <TextField
              value={data?.height}
              onChange={onChange}
              name="height"
              placeholder="Altura"
              title="Altura (cm)"
              style={{ maxWidth: 120 }}
            />
            <div style={{ marginBottom: 15, padding: 10 }}>X</div>
            <TextField
              value={data?.length}
              onChange={onChange}
              name="length"
              placeholder="Profundidade"
              title="Profundidade (cm)"
              style={{ maxWidth: 120 }}
            />
          </div>
          <TextField
            value={data?.id_sap}
            onChange={onChange}
            name="id_sap"
            placeholder="ID John Richard"
            title="ID John Richard"
            style={{ maxWidth: 120 }}
          />
          <div>
            <div
              style={{
                fontSize: 12,
                fontWeight: "500",
                marginBottom: 5,
              }}
            >
              Cor
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {data?.colors?.map((item, index) => (
                <div
                  key={`${index}_${item.color}`}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                >
                  <input
                    type="text"
                    style={{
                      height: 30,
                      width: 70,
                      fontSize: 14,
                      fontWeight: "600",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "gray",
                      backgroundColor: item.color,
                      color:
                        lightOrDark(item.color) == "light" ? "#000" : "#FFF",
                    }}
                    value={item.color}
                    onChange={(e) => onChangeColor(e, index)}
                    name="color"
                  />
                  <RemoveButton
                    onClick={() => onRemoveColor(index)}
                    style={{ marginLeft: -5, marginTop: -5 }}
                  />
                </div>
              ))}
              <AddButton text="+ Cor" onClick={onAddColor} />
            </div>
          </div>

          <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <Text strong>Características</Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              {data?.features != null
                ? data?.features?.map((item, index) => (
                    <div
                      key={`${item.title}_listing_${index}`}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: 15,
                      }}
                    >
                      <div
                        style={{
                          borderColor: "gray",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: 5,
                          padding: 7,
                          fontSize: 12,
                        }}
                      >
                        {item.title}
                      </div>
                      <RemoveButton onClick={() => removeFeature(index)} />
                    </div>
                  ))
                : ""}
              <AddButton
                text="+ Característica"
                onClick={() => {
                  setSelectorModal({
                    open: true,
                    data: features.map((item) => item.title),
                    callback: onAddFeature,
                  });
                }}
              />
            </div>
          </Box>

          <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <Text strong>Disponibilidade</Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              {data?.units != null &&
                data?.units?.map((item, index) => (
                  <div
                    key={`${item.title}_listing_units_${index}`}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: 15,
                    }}
                  >
                    <div
                      style={{
                        borderColor: "gray",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderRadius: 5,
                        padding: 7,
                        fontSize: 12,
                      }}
                    >
                      {item.title}
                    </div>
                    <RemoveButton onClick={() => removeUnit(index)} />
                  </div>
                ))}
              <AddButton
                text="+ Filial"
                onClick={() => {
                  setSelectorModal({
                    open: true,
                    data: units.map((item) => item.title),
                    callback: onAddUnit,
                  });
                }}
              />
            </div>
          </Box>
          <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <Text strong>Estilos</Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              {data?.styles != null &&
                data?.styles?.map((item, index) => (
                  <div
                    key={`${item.title}_listing_styles_${index}`}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: 15,
                    }}
                  >
                    <div
                      style={{
                        borderColor: "gray",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderRadius: 5,
                        padding: 7,
                        fontSize: 12,
                      }}
                    >
                      {item.title}
                    </div>
                    <RemoveButton onClick={() => removeStyle(index)} />
                  </div>
                ))}
              <AddButton
                text="+ Estilo"
                onClick={() => {
                  setSelectorModal({
                    open: true,
                    data: styles.map((item) => item.title),
                    callback: onAddStyle,
                  });
                }}
              />
            </div>
          </Box>

          <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <Text strong>Ambientes</Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              {data?.ambients != null &&
                data?.ambients?.map((item, index) => (
                  <div
                    key={`${item.title}_listing_ambients_${index}`}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: 15,
                    }}
                  >
                    <div
                      style={{
                        borderColor: "gray",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderRadius: 5,
                        padding: 7,
                        fontSize: 12,
                      }}
                    >
                      {item.title}
                    </div>
                    <RemoveButton onClick={() => removeAmbient(index)} />
                  </div>
                ))}
              <AddButton
                text="+ Ambiente"
                onClick={() => {
                  setSelectorModal({
                    open: true,
                    data: ambients.map((item) => item.title),
                    callback: onAddAmbient,
                  });
                }}
              />
            </div>
          </Box>
          {selectorModal.open && (
            <Selector
              open={selectorModal.open}
              onSelect={(item) => {
                selectorModal.callback(item);
                setSelectorModal({ open: false });
                // selectorCallback(item);
              }}
              title="Selecione"
              data={selectorModal.data}
              // unit={units_data == selectorData ? true : false}
              onClose={() => setSelectorModal({ open: false })}
            />
          )}
        </ContentContainer>
      </Box>
    </>
  );
};

// const styles = {
//   dropZone: {
//     // backgroundColor: 'red',
//     display: "flex",
//     flex: 1,
//     padding: 15,
//     justifyContent: "center",
//     alignItems: "center",
//     margin: 15,
//     borderRadius: 10,
//     flexDirection: "column",
//     borderWidth: 1,
//     borderStyle: "dashed",
//     borderColor: "gray",
//     maxHeight: 150,
//     cursor: "pointer",
//   },
// };

export default EditProduct;
