import { Box, Link } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import moment from "moment";
import React, { useCallback } from "react";
import { formatter, pluralize } from "../../functions";
import { Colors } from "../../styles/Colors";
import { TuimButton } from "../layout/Button";
import { Text } from "../layout/components/text";

moment.locale("pt-br");

export default function VisibilityReturnProduct({ products = [], period }) {
  const cancelReturn = useCallback(async (item) => {
    const token = localStorage.getItem("token");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/v2/cancel-return`, item, {
        headers: { Authorization: "bearer " + token },
      })
      .then(() => window.location.reload())
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <Table style={{ minWidth: 662 }} aria-label="simple table">
        <TableBody>
          {products.map((item, key) => {
            const price = item.qtd * item.product.discounts[period].price;
            return (
              <TableRow key={key} sx={{ margin: 0 }}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ display: "flex", flex: 1 }}
                >
                  <Link
                    target="_blank"
                    href={`/app/product/${item?._id}`}
                    style={{
                      display: "flex",
                      flex: 1,
                      textDecoration: "none",
                    }}
                  >
                    <Box style={{ display: "flex", gap: 30, flex: 1 }}>
                      <div
                        style={{
                          backgroundImage: `url(${item?.urlImg})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          width: 120,
                          height: 80,
                          borderRadius: 8,
                        }}
                      />
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 8,
                        }}
                      >
                        <Text strong style={{ fontSize: 18 }} color="#444">
                          {item?.name}
                        </Text>
                        <Box style={{ display: "flex", gap: 10 }}>
                          <Box
                            style={{
                              display: "flex",
                              backgroundColor: Colors.paleYellow,
                              padding: `2px 18px`,
                              borderRadius: 20,
                              alignItems: "center",
                            }}
                          >
                            <Text detail strong color="#444">
                              {item?.qtd} {pluralize("unidade", item?.qtd)}
                            </Text>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              backgroundColor: Colors.paleYellow,
                              padding: `2px 18px`,
                              borderRadius: 20,
                              alignItems: "center",
                            }}
                          >
                            <Text detail strong color="#444">
                              {formatter.currency(price)}
                              /mês
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Link>

                  <Box
                    style={{
                      display: "flex",
                      gap: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TuimButton
                      fullWidth
                      text="Cancelar Devolução"
                      destructive
                      onClick={() => cancelReturn(item)}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
