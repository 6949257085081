import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import LoadingOverlay from "react-loading-overlay";

const ImageList = (props) => {
  return (
    <div className={props.classes.container}>
      {props.data?.map((item, index) => (
        <div className={props.classes.item} key={index}>
          <LoadingOverlay active={!item.uploaded} spinner text="">
            <div
              className={props.classes.img}
              style={{
                backgroundImage: `url(${item.url ? item.url : item.preview})`,
              }}
            ></div>
          </LoadingOverlay>
          <div className={props.classes.itemContent}>
            <div style={{ marginBotttom: 10 }}>{item.name}</div>
            <div
              style={{ cursor: "pointer", color: "#084047" }}
              onClick={() => props.setImageDefault(item.url)}
            >
              Definir como principal
            </div>
            <div
              style={{ cursor: "pointer", color: "#084047" }}
              onClick={() => props.setSecondaryImage(item.url)}
            >
              Definir como secundária
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "red",
                color: "#FFF",
                borderRadius: 10,
                width: 20,
                height: 20,
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => props.onDeleteImage(item.id)}
            >
              x
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  item: {
    marginBottom: 15,
    padding: 5,
    fontSize: 12,
    display: "flex",
    flexDirection: "row",
  },
  img: {
    width: 70,
    height: 70,
    backgroundColor: "gray",
    borderRadius: 8,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  itemContent: {
    paddingLeft: 10,
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default withStyles(styles)(ImageList);
