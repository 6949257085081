import React from "react";
import { formatter, pluralize } from "../../functions";

export const NewBudgetPdf = React.forwardRef(({ data }, ref) => {
  const budgetLink = `https://www.tuim.com.br/carrinho?orcamento=${data._id}`;

  return (
    // <></>
    <div data-print="true" ref={ref} style={styles.body}>
      <div style={styles.budgetInfosContainer}>
        <img
          src="/images/logo-tuim.svg"
          alt="Logo"
          width={150}
          style={styles.logoImage}
        />

        <h1 style={styles.title}>Aqui está o seu orçamento</h1>

        <p>
          Olá, <span style={styles.clientName}>{data.name}</span>, informação
          importante para o seu plano de voo: o orçamento que você pediu está
          pronto. Confira:
        </p>

        <img
          src="/images/clipboard.png"
          alt="Prancheta"
          width={280}
          style={styles.clipBoardImage}
        />
        <p style={styles.myPlanTitle}>Plano de assinatura:</p>

        <div style={styles.myPlanInfos}>
          <p>
            Montagem e Entrega:{" "}
            <span style={styles.strong}>
              {formatter.currency(data.priceDelivery)}
            </span>
          </p>
          <p>
            Valor Original:{" "}
            <span style={styles.strong}>
              {formatter.currency(data.priceWithoutDiscount)}
            </span>
          </p>

          {data?.coupon?.code?.cartDiscount ? (
            <p>
              {`Valor do Desconto (${String(
                data.coupon.cartDiscount.percentage
              )}%): `}
              <span style={styles.strong}>
                {formatter.currency(data.coupon.cartDiscount.value)}
              </span>
            </p>
          ) : (
            <></>
          )}
          <p>
            Valor Mensal:{" "}
            <span style={styles.strong}>{formatter.currency(data.price)}</span>
          </p>
          {data?.coupon?.code ? (
            <p>
              Cupom Aplicado:{" "}
              <span style={styles.couponName}>{data?.coupon?.code}</span>
            </p>
          ) : (
            <></>
          )}
          <p>
            Período de contrato:{" "}
            <span style={styles.strong}>{`${String(data.period)} ${pluralize(
              "mês",
              data.period
            )}`}</span>
          </p>
        </div>
      </div>

      <div style={styles.line}></div>

      <p style={styles.tableTitle}>Itens do seu pedido:</p>

      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.tableData}></th>
            <th style={styles.tableData}>Nome</th>
            <th style={styles.tableData}>Valor</th>
            <th style={styles.tableData}>Qtd</th>
          </tr>
        </thead>
        <tbody>
          {data?.products?.map((item) => (
            <tr>
              <td style={styles.tableData}>
                <img
                  src={item.urlImg}
                  alt="Imagem do móvel"
                  style={styles.productImage}
                />
              </td>
              <td style={styles.tableDataName}>{item.name}</td>
              <td style={styles.tableData}>{formatter.currency(item.price)}</td>
              <td style={styles.tableDataQtd}>{item.qtd}</td>
            </tr>
          ))}
          {data?.coupon?.code ? (
            <tr>
              <td style={styles.tableData}></td>
              <td style={styles.tableDataName}>
                Desconto do cupom {data.coupon.code}
              </td>
              <td style={styles.tableData}>
                {formatter.currency(data.coupon.cartDiscount.value)}
              </td>
              <td style={styles.tableDataQtd}>1</td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>

      <div style={styles.buttonContainer}>
        <a
          style={styles.buttonNavigate}
          href={budgetLink}
          rel="Budget noopener noreferrer"
          target="_blank"
        >
          Quero fazer meu pedido
        </a>
      </div>
    </div>
  );
});

const styles = {
  body: {
    display: "none",
    width: "100%",
    padding: "50px",
  },

  budgetInfosContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  logoImage: {
    alignSelf: "end",
    marginBottom: 30,
  },

  title: {
    color: "#084047",
    fontSize: 50,
  },

  clientName: {
    color: "#00c830",
  },

  clipBoardImage: {
    margin: "60px 0",
  },

  myPlanTitle: {
    color: "#084047",
  },

  myPlanInfos: {
    marginBottom: 50,
  },

  strong: {
    fontWeight: 600,
  },

  couponName: {
    color: "#00c830",
    fontWeight: 600,
  },

  tableTitle: {
    paddingTop: 60,
    textAlign: "center",
    color: "#084047",
  },

  table: {
    padding: "40px 0",
    width: "100%",
    textAlign: "left",
  },

  tableData: {
    width: 100,
    padding: 10,
  },

  tableDataQtd: {
    width: 50,
  },

  tableDataName: {
    width: 300,
    maxWidth: 300,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    padding: 10,
  },

  productImage: {
    maxWidth: 100,
  },

  buttonContainer: {
    width: "100%",
    textAlign: "center",
    marginTop: 40,
  },

  buttonNavigate: {
    cursor: "pointer",
    fontSize: 14,
    color: "#00c830",
    fontWeight: 700,
  },
};
