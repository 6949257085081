import { Box, Link } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { HiBadgeCheck } from "react-icons/hi";
import { MdAlternateEmail } from "react-icons/md";
import { formatter, parseUserAgent, pluralize } from "../../functions";
import { Colors } from "../../styles/Colors";
import { ExternalLink } from "../ExternalLink/ExternalLink";
import { TuimButton } from "../layout/Button";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { InternalNotes } from "../layout/components/internalNotes/internalNotes";
import { Rows } from "../layout/components/rows";
import { Text } from "../layout/components/text";
import Loading from "../utils/Loading";
import { CartHistory } from "./CartHistory";
import { CreateContract } from "./createContract";

const COUPON_TYPES = {
  cart: "Carrinho",
  delivery: "Entrega",
  "cart-delivery": "Carrinho e Entrega",
};
const token = localStorage.getItem("token");

export const Cart = () => {
  const [cart, setCart] = useState({});
  const [historyHeight, setHistoryHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [handleModal, setHandleModal] = useState(false);

  const cartInfosHeight = useRef(null);
  const id = window.location.href.split("/").at(-1);
  const saleMade = cart?.saleMade || cart?.contractId;

  useEffect(() => {
    const getCart = async () => {
      setLoading(true);
      await axios
        .get(`${process.env.REACT_APP_API_URL}/v2/getcarts/${id}`, {
          headers: { Authorization: "bearer " + token },
        })
        .then((response) => {
          setCart(response.data.content);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    };
    getCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cartInfosHeight?.current?.clientHeight)
      setHistoryHeight(cartInfosHeight?.current?.clientHeight);
  }, [loading]);

  const handleOpen = () => {
    setHandleModal(!handleModal);
  };

  const deleteCart = async (id) => {
    const token = localStorage.getItem("token");

    await axios
      .delete(`${process.env.REACT_APP_API_URL}/v2/carts/${id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        if (response.data.success) {
          window.location.href = "/app/carts/";
        }
      })
      .catch((error) => {
        alert("Desculpe\n\nHouve um problema ao descartar o carrinho.");
        console.log(error);
      });
  };

  const coupon = {
    common: [
      {
        label: "Cupom",
        description: cart?.price?.coupon?.name || cart?.coupon?.code || "-",
      },
      {
        label: "Aplicação",
        description:
          COUPON_TYPES[cart?.price?.coupon?.type || cart?.coupon?.type] || "-",
      },
    ],
    cart: [
      {
        label: "Valor Original",
        description:
          formatter?.currency(
            cart?.price?.priceWithoutDiscount ||
              cart?.costs?.originalSubscription
          ) || "-",
      },
      {
        label: `Desconto no Carrinho (${
          cart?.price?.coupon?.discountCart ||
          cart?.coupon?.cartDiscount?.percentage
        }%)`,
        description:
          formatter.currency(
            cart?.price?.coupon?.discountValue ||
              cart?.coupon?.cartDiscount?.value
          ) || "-",
      },
      {
        label: "Valor Mensal",
        description:
          formatter.currency(
            cart?.price?.priceWithDiscount || cart?.costs?.subscription
          ) || "-",
      },
    ],
    delivery: [
      {
        label: `Desconto na Entrega`,
        description:
          `${
            cart?.price?.coupon?.discountDelivery ||
            cart?.coupon?.deliveryDiscount?.percentage
          }%` || "-",
      },
      {
        label: "Valor Mensal",
        description:
          formatter.currency(
            cart?.price?.priceWithoutDiscount || cart?.costs?.subscription
          ) || "-",
      },
    ],
  };
  coupon["cart-delivery"] = [
    ...coupon["delivery"].filter(({ label }) => label !== "Valor Mensal"),
    ...coupon["cart"],
  ];
  const { device, browser, browserVersion } = parseUserAgent(cart?.device);

  const totalProducts = cart._id
    ? Math.max(
        cart?.populatedProducts?.length,
        Object.keys(cart?.products || {}).length
      )
    : 0;

  const cartProducts = Array.isArray(cart?.products)
    ? cart?.products
    : Object.entries(cart?.products || {}).map(([key, value]) => ({
        id: { _id: key, ...value },
        qtd: value.qtd,
      }));
  // cart._id
  //   ? cart.populatedProducts.concat(
  //       Object.entries(cart?.products || {}).map(([key, value]) => ({
  //         _id: key,
  //         ...value,
  //       }))
  //     )
  //   : [];
  console.log(cartProducts);

  if (loading) return <Loading loading={loading} />;

  return (
    <>
      <ContentHeader
        title={cart?.id_user?.name || "Desconhecido"}
        link={cart?.id_user ? `/app/client/${cart?.id_user?._id}` : null}
        subtitle={`Criado em ${formatter.date(cart?.createdAt, "short")}`}
        buttons={[
          ...(cart?.contractId
            ? [
                {
                  text: "Ver Contrato",
                  tertiary: true,
                  onClick: () =>
                    window.open(`/app/contract/${cart?.contractId}`),
                },
              ]
            : []),
          {
            text: "Descartar Carrinho",
            destructive: true,
            onClick: () => handleOpen(),
          },
        ]}
      />
      {/* <Button onClick={() => deleteCart(cart._id)} color="primary">
        <IoTrashOutline
          size={20}
          color={ Colors.red}
        />
      </Button> */}
      <div style={{ display: "flex", gap: 28 }}>
        <div
          ref={cartInfosHeight}
          height="fit-content"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 28,
            flex: 1,
            height: "fit-content",
          }}
        >
          <ContentContainer
            label="Dados do carrinho"
            style={{ position: "relative" }}
          >
            <Rows
              data={[
                {
                  label: "Periodo",
                  description: `${cart?.period} ${pluralize(
                    "mês",
                    cart?.period
                  )}`,
                },
                { label: "CEP", description: cart?.cep || "-" },
                ...(cart?.price?.coupon?.assemblyAndDelivery
                  ? [
                      {
                        label: "Montagem e Entrega",
                        description:
                          formatter.currency(
                            cart?.price?.coupon?.assemblyAndDelivery
                          ) || "-",
                      },
                      {
                        label: "Primeira Mensalidade",
                        description:
                          formatter.currency(
                            cart?.price?.coupon?.assemblyAndDelivery +
                              cart?.price?.priceWithoutDiscount
                          ) || "-",
                      },
                    ]
                  : []),
                ...(cart?.price?.coupon?.name || cart?.coupon?.code
                  ? [
                      ...coupon["common"],
                      ...coupon[
                        cart?.price?.coupon?.type || cart?.coupon?.type
                      ],
                    ]
                  : [
                      {
                        label: "Valor Mensal",
                        description:
                          formatter.currency(
                            cart?.price?.priceWithoutDiscount ||
                              cart?.costs?.subscription
                          ) || "-",
                      },
                    ]),
                {
                  label: "Data de entrega",
                  description: formatter.date(
                    cart?.deliveryDate,
                    "short",
                    false
                  ),
                },
                {
                  label: "Última atualização",
                  description: formatter.date(cart?.updatedAt, "short"),
                },
                { label: "Dispositivo", description: device },
                {
                  label: "Browser",
                  description: `${browser} (Versão ${browserVersion})`,
                },
                ...(cart.abandonDetails?.reason
                  ? [
                      {
                        label: "Abandono",
                        description: `${
                          cart.abandonDetails?.reason
                        } - ${formatter.date(
                          cart.abandonDetails?.createdAt,
                          "short",
                          true
                        )}`,
                      },
                    ]
                  : []),
              ]}
            />

            {saleMade ? (
              <Box style={styles.saleMade}>
                <HiBadgeCheck size={20} color={Colors.lightGreen} />
              </Box>
            ) : (
              <Box>
                <CreateContract data={cart} />
              </Box>
            )}
          </ContentContainer>

          {cart?.id_user && (
            <ContentContainer label="Dados do Usuário">
              <Rows
                data={[
                  { label: "Nome", description: cart.id_user.name },
                  {
                    label: "E-mail",
                    description: (
                      <ExternalLink
                        data={cart?.id_user?.email}
                        icon={<MdAlternateEmail size={20} color="#688284" />}
                        action={() =>
                          window.navigator.clipboard
                            .writeText(cart?.id_user?.email)
                            .then(() => alert("E-mail copiado!"))
                        }
                      />
                    ),
                  },
                  {
                    label: "Celular",
                    description: (
                      <ExternalLink
                        data={cart?.id_user?.mobileNumber}
                        icon={<FaWhatsapp size={20} color="#688284" />}
                        action={() =>
                          window.open(
                            `http://api.whatsapp.com/send/?phone=55${cart?.id_user?.mobileNumber.replace(
                              /\D/g,
                              ""
                            )}`
                          )
                        }
                      />
                    ),
                  },
                ]}
              />
            </ContentContainer>
          )}
        </div>

        <ContentContainer
          fullWidth
          label="Histórico"
          overflow
          style={{
            minWidth: 480,
            boxSizing: "border-box",
            overflow: "hidden",
            height: historyHeight,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20,
              height: "100%",
            }}
          >
            {cart.history?.length > 0 ? (
              cart.history?.map((event, index) => {
                const lastChild = cart.history.length === index + 1;
                return (
                  <CartHistory
                    key={event._id}
                    event={event}
                    lastChild={lastChild}
                  />
                );
              })
            ) : (
              <Box
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ color: "#aaa" }}>Nenhum evento registrado</Text>
              </Box>
            )}
          </Box>
        </ContentContainer>
      </div>

      <ContentContainer label={`Produtos (${totalProducts})`}>
        <Box style={{ display: "flex", flexDirection: "column", gap: 28 }}>
          {totalProducts > 0 ? (
            <Table style={{ minWidth: 662 }} aria-label="simple table">
              <TableBody>
                {cartProducts.map((product) => {
                  console.log(product);
                  return (
                    <>
                      <TableRow sx={{ margin: 0 }}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ display: "flex", flex: 1 }}
                        >
                          <Link
                            target="_blank"
                            href={`/app/product/${product.id._id}`}
                            style={{
                              display: "flex",
                              flex: 1,
                              textDecoration: "none",
                            }}
                          >
                            <Box style={{ display: "flex", gap: 30, flex: 1 }}>
                              <div
                                style={{
                                  backgroundImage: `url(${product.id.urlImg})`,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                  width: 120,
                                  height: 80,
                                  borderRadius: 8,
                                }}
                              />
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 8,
                                }}
                              >
                                <Text
                                  strong
                                  style={{ fontSize: 18 }}
                                  color="#444"
                                >
                                  {product.id.name}
                                </Text>
                                <Box style={{ display: "flex", gap: 10 }}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      backgroundColor: Colors.paleYellow,
                                      padding: `2px 18px`,
                                      borderRadius: 20,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text detail strong color="#444">
                                      {product.qtd}{" "}
                                      {pluralize("unidade", product.qtd)}
                                    </Text>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Link>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Text detail style={{ textAlign: "center", margin: `40px 0px` }}>
              Nenhum produto no carrinho
            </Text>
          )}
        </Box>
      </ContentContainer>

      <ContentContainer label="Observações Internas" fullWidth>
        <InternalNotes reference={{ type: "Cart", id }} />
      </ContentContainer>

      {handleModal && (
        <>
          <Box style={{ zIndex: 9999 }}>
            <Box style={styles.root}>
              <ContentContainer
                style={{ maxHeight: "80%", position: "relative" }}
                gap={24}
              >
                <Box style={{ marginRight: 12 }}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 14,
                      minWidth: 360,
                      marginTop: 32,
                    }}
                  >
                    <h3>Tem certeza que deseja descartar este carrinho?</h3>
                  </Box>
                </Box>

                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 10,
                    marginTop: 12,
                  }}
                >
                  <TuimButton tertiary text={"Cancelar"} onClick={handleOpen} />
                  <TuimButton
                    destructive
                    text={"Descartar Carrinho"}
                    onClick={() => deleteCart(cart._id)}
                  />
                </Box>
              </ContentContainer>
            </Box>
            )
          </Box>
        </>
      )}
    </>
  );
};

const styles = {
  saleMade: {
    position: "absolute",
    top: 28,
    right: 28,
  },
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#00000044",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 99999,
  },
  main: {
    backgroundColor: "#fff",
    borderRadius: 14,
    padding: 20,
    display: "flex",
    flexDirection: "column",
  },
  InputProps: {
    style: {
      fontSize: 14,
    },
  },
  InputLabelProps: {
    shrink: true,
  },
};
