import { Box, TableCell, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "@material-ui/core";
import { Text } from "./components/text";
import { Colors } from "../../styles/Colors";
import { formatter, pluralize } from "../../functions";
import { getPriceByProductItem } from "../../functions/calcCartPrice/calcCartPrice";
import ReturnProduct from "../contracts/ReturnProduct";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

export const ProductCard = ({
  product,
  qtd,
  period,
  styles = {},
  data = null,
  returnProductOption = false,
  getContract = null,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const price = product?.discounts
    ? getPriceByProductItem(product, period)
    : product.price;

  return (
    <>
      <TableRow key={product.id} sx={{ margin: 0 }}>
        <TableCell
          component="th"
          scope="row"
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            ...(product.kit ? { cursor: "pointer" } : {}),
          }}
          onClick={() => setShowDropDown((prev) => !prev)}
        >
          <Box
            style={{
              display: "flex",
              gap: 30,
              flex: 1,
              ...styles,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${product.urlImg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: 120,
                height: 80,
                borderRadius: 8,
              }}
            />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <Link
                target="_blank"
                href={
                  product.kit
                    ? `/app/kit/${product._id}`
                    : `/app/product/${product._id}`
                }
                style={{
                  display: "flex",
                  flex: 1,
                  textDecoration: "none",
                }}
              >
                <Text strong style={{ fontSize: 18 }} color="#444">
                  {product.name}
                </Text>
              </Link>
              <Box style={{ display: "flex", gap: 10 }}>
                <Box
                  style={{
                    display: "flex",
                    backgroundColor: Colors.paleYellow,
                    padding: `2px 18px`,
                    borderRadius: 20,
                    alignItems: "center",
                  }}
                >
                  <Text detail strong color="#444">
                    {qtd} {pluralize("unidade", qtd)}
                  </Text>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    backgroundColor: Colors.paleYellow,
                    padding: `2px 18px`,
                    borderRadius: 20,
                    alignItems: "center",
                  }}
                >
                  <Text detail strong color="#444">
                    {formatter.currency(price)}
                    /mês
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>

          {returnProductOption && (
            <ReturnProduct
              contract={data}
              productSelectedReturn={product}
              getContract={getContract}
            />
          )}

          {product.kit && !showDropDown ? (
            <IoIosArrowDown
              size={20}
              style={{ marginRight: 10, position: "absolute", right: 18 }}
            />
          ) : product.kit && showDropDown ? (
            <IoIosArrowUp
              size={20}
              style={{ marginRight: 10, position: "absolute", right: 18 }}
            />
          ) : null}
        </TableCell>
      </TableRow>

      {product.kit &&
        showDropDown &&
        product.products.map((item, index) => {
          return (
            <ProductCardList
              period={period}
              product={item.product}
              qtd={qtd}
              key={`$${item.product.name} - ${index} - dropdown`}
            />
          );
        })}
    </>
  );
};

const ProductCardList = ({ product, qtd, period }) => {
  return (
    <ProductCard
      product={product}
      qtd={qtd}
      period={period}
      styles={{ marginLeft: 40 }}
    />
  );
};
