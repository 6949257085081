import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import React, { Component } from "react";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { Rows } from "../layout/components/rows";
import AlertDialog from "../utils/AlertDialog";
import Loading from "../utils/Loading";

const Unknown = () => <span style={{ color: "#999" }}>Não identificado</span>;
class EditWarn extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      classes,
      data: {
        _id,
        name: "",
        email: "",
        product: [
          {
            name: "",
          },
        ],
        createdAt: "",
        status: "",
        type: "",
      },
      saving: false,
      success: false,
      error: null,
      anchorEl: null,
      open: false,
      showAlert: false,
      showDeleteCouponAlert: false,
      loading: true,
    };

    this.onChange = this.onChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onDeleteCouponPress = this.onDeleteCouponPress.bind(this);
    this.deleteCoupon = this.deleteCoupon.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
  }

  componentDidMount = async () => {
    const { _id } = this.state.data;

    if (!_id) {
      this.setState({ loading: false });
      return;
    }

    const token = localStorage.getItem("token");
    const _this = this;

    axios
      .get(`${process.env.REACT_APP_API_URL}/v2/admin/warnings/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          data: data,
        });

        this.setState({ loading: false });
      });
  };

  onChange = (e) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [e.target.name]: e.target.value,
      },
      success: false,
    });
  };

  onSavePress = async () => {
    const { data } = this.state;

    if (!data._id) {
      this.newCoupon();
      return;
    }

    this.setState({
      saving: true,
    });

    const token = localStorage.getItem("token");

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/warnMe/${data._id}`,
        { status: data.status },
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });

        window.location.reload();
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  newCoupon = async () => {
    const { data } = this.state;

    data._id = undefined;

    this.setState({
      saving: true,
    });

    const token = localStorage.getItem("token");
    const _this = this;

    axios
      .post(`${process.env.REACT_APP_API_URL}/coupon`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
          data: response.data.data,
        });

        this.props.history.goBack();

        _this.props.history.push(`${response.data.data._id}`);
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  deleteCoupon = async () => {
    const { data } = this.state;
    const token = localStorage.getItem("token");

    axios
      .delete(`${process.env.REACT_APP_API_URL}/warnMe/${data._id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.props.history.push("/app/warns");
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  onCancelPress = () => {
    this.props.history.goBack();
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, showAlert: false });
  };

  onDeleteCouponPress = () => {
    this.setState({ anchorEl: null, open: false, showDeleteCouponAlert: true });
  };

  onCheckChange = (name) => (event) => {
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        [name]: event.target.checked,
      },
      success: false,
    });
  };

  render() {
    const {
      classes,
      saving,
      success,
      anchorEl,
      open,
      showDeleteCouponAlert,
      loading,
    } = this.state;
    const { data } = this.state;

    return (
      <>
        <Loading loading={loading} />
        <ContentHeader
          title={`Solicitação de ${data?.name}`}
          subtitle={data.createdAt ? `Registrada em ${data.createdAt}` : ""}
          buttons={[
            {
              text: "Salvar",
              secondary: true,
              onClick: this.onSavePress,
            },
            {
              text: "Excluir",
              destructive: true,
              onClick: this.onDeleteCouponPress,
            },
          ]}
        />
        <Box style={{ display: "flex", gap: 24 }}>
          <ContentContainer fullWidth>
            <Rows
              rowGap={4}
              columnGap={2}
              data={[
                { label: "Nome", description: data.name || <Unknown /> },
                {
                  label: "E-mail",
                  description: data.email,
                },
                {
                  label: "Celular",
                  description: data.mobileNumber || <Unknown />,
                },
                {
                  label: "Produto",
                  description: data.product?.name || <Unknown />,
                },
              ]}
            />

            <FormControl fullWidth>
              <InputLabel id="warning-status">Status</InputLabel>
              <Select
                labelId="warning-status"
                id="warning"
                name="status"
                value={data.status}
                onChange={this.onChange}
              >
                {["Aberto", "Finalizado"].map((item, index) => (
                  <MenuItem
                    key={`list_warning_status_options_${item}_${index}`}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {showDeleteCouponAlert && (
              <AlertDialog
                msg={`Deseja relmente excluir esta solicitação? Esta ação é irreversível`}
                title="Excluir Solicitação de Aviso"
                open={showDeleteCouponAlert}
                buttons={[
                  {
                    label: "Cancelar",
                    onPress: () => {
                      this.setState({
                        showDeleteCouponAlert: false,
                      });
                    },
                  },
                  {
                    label: "Excluir",
                    onPress: () => {
                      this.setState({
                        showDeleteCouponAlert: false,
                      });

                      this.deleteCoupon();
                    },
                    color: "primary",
                  },
                ]}
              />
            )}
          </ContentContainer>
        </Box>
      </>
    );
  }
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    maxWidth: "450px",
    marginTop: theme.spacing.unit,
  },
  button: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  success: {
    backgroundColor: green[600],
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  titleName: {
    flex: 1,
  },
  titleBar: {
    flexDirection: "row",
  },
});

export default withStyles(styles)(EditWarn);
