import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import axios from "axios";
import Numeral from "numeral";
import "numeral/locales/pt-br";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Moment from "react-moment";
import { TuimButton } from "../layout/Button";
import { ContentContainer } from "../layout/components/contentContainer/index";
import ContentHeader from "../layout/components/contentHeader/index";
import Loading from "../utils/Loading";
import { formatter, pluralize } from "../../functions";
Numeral.locale("pt-br");

class ListBudgets extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      classes,
      data: [],
      searchTerm: "",
      page: 0,
      rowsPerPage: 15,
      order: "asc",
      orderBy: "calories",
      limit: 25,
      cont: 0,
      email: "",
      loading: false,
    };

    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount = async () => {
    this.getBudget();
  };
  filter = async () => {
    const { email, page, limit } = this.state;
    let url = new URL(window.location.href);
    if (url.searchParams.get("page")) {
      if (!url.searchParams.get("page")) {
        this.setState({ page: url.searchParams.get("page") });
      } else {
        let returnPage = url.searchParams.get("page");
        this.setState({ page: parseInt(returnPage) });
      }
    }
    let filter = {};
    if (email != "") {
      filter.email = email;
    }
    const token = await localStorage.getItem("token");
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/budgets`, {
        params: {
          filter,
          page: page,
          limit: limit,
        },
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data, cont } = response.data;
        this.setState({
          data: data,
          cont: cont,
        });
      })
      .finally(() => this.setState({ loading: false }));
  };
  getBudget = async (pageGo = 0) => {
    const { limit } = this.state;
    const _this = this;
    const token = await localStorage.getItem("token");
    let url = new URL(window.location.href);
    let returnPage = url.searchParams.get("page");
    if (url.searchParams.get("page")) {
      if (!url.searchParams.get("page")) {
        this.setState({ page: url.searchParams.get("page") });
      } else {
        let returnPage = url.searchParams.get("page");
        this.setState({ page: parseInt(returnPage) });
      }
    }
    _this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/budgets`, {
        params: {
          page: parseInt(returnPage) ? parseInt(returnPage) : pageGo,
          limit: limit,
        },
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data, cont } = response.data;
        _this.setState({
          data: data,
          cont: cont,
        });
      })
      .finally(() => _this.setState({ loading: false }));
  };

  onSearchChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  handleChangePage = (event, page) => {
    const { page: pagina } = this.state;
    let pageGo = 0;
    if (page > pagina) {
      pageGo = pagina + 1;
    } else {
      pageGo = pagina - 1;
    }
    window.history.pushState(
      "atualpage",
      "TUIM - Admin",
      window.location.pathname + "?page=" + page
    );
    this.setState({ page: pageGo });
    this.getBudget(pageGo);
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  handleRequestSort = async (event, property) => {
    const { page, limit } = this.state;
    const orderBy = property;
    let order = "desc";
    const _this = this;
    const token = await localStorage.getItem("token");
    let url = new URL(window.location.href);
    let returnPage = url.searchParams.get("page");
    if (url.searchParams.get("page")) {
      if (!url.searchParams.get("page")) {
        this.setState({ page: url.searchParams.get("page") });
      } else {
        let returnPage = url.searchParams.get("page");
        this.setState({ page: parseInt(returnPage) });
      }
    }

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    _this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/budgets`, {
        params: {
          page: parseInt(returnPage) ? parseInt(returnPage) : page,
          limit: limit,
          sort: order == "asc" ? 1 : -1,
          nameColumn: property,
        },
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data, cont } = response.data;
        _this.setState({
          data: data,
          cont: cont,
        });
      })
      .finally(() => _this.setState({ loading: false }));

    this.setState({ order, orderBy });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };
  enterButton = (e) => {
    if (e.key === "Enter") {
      this.filter();
    }
  };

  render() {
    const {
      classes,
      data,
      page,
      rowsPerPage = 15,
      order,
      orderBy,
      cont,
      email,
      loading,
    } = this.state;
    const arredondar = (nr) => {
      if (nr) {
        return Math.round(nr, -1).toFixed(2);
      }
      return "-";
    };

    const columns = [
      { id: "name", numeric: false, disablePadding: false, label: "Nome" },
      { id: "period", numeric: true, disablePadding: false, label: "Periodo" },
      {
        id: "price",
        numeric: true,
        disablePadding: false,
        label: "Preço",
      },
      {
        id: "createdAt",
        numeric: true,
        disablePadding: false,
        label: "Data Contrato",
      },
      {
        id: "status",
        numeric: true,
        disablePadding: false,
        label: "Status",
      },
    ];

    return (
      <>
        <Loading loading={loading} />
        <ContentHeader
          title="Orçamentos"
          buttons={[
            {
              text: "Novo",
              secondary: true,
              onClick: () => this.props.history.push(`/app/NewBudget/`),
            },
          ]}
        />

        <ContentContainer>
          <Box
            style={{
              display: "flex",
              width: "100%",
              gap: 20,
            }}
          >
            <TextField
              id="data-contract"
              label="Email"
              type="text"
              value={email}
              fullWidth
              onChange={(e) => this.setState({ email: e.target.value })}
              onKeyPress={(e) => this.enterButton(e)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <TuimButton tertiary text="Filtrar" onClick={this.filter} />
            </div>
          </Box>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={cont}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Página anterior",
            }}
            nextIconButtonProps={{
              "aria-label": "Próxima página",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map(
                  (row) => (
                    <TableCell
                      key={row.id}
                      align={row.align}
                      padding={row.disablePadding ? "none" : "default"}
                      sortDirection={orderBy === row.id ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement={row.numeric ? "bottom-end" : "bottom-start"}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === row.id}
                          direction={order}
                          onClick={this.createSortHandler(row.id)}
                        >
                          {row.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  ),
                  this
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row) => {
                const price =
                  typeof row?.costs?.subscription === "number"
                    ? formatter.currency(row?.costs?.subscription)
                    : row.price?.priceWithoutDiscount
                    ? `R$ ${arredondar(
                        row?.price?.priceWithDiscount ||
                          row?.price?.priceWithoutDiscount
                      )}`
                    : `Não informado`;

                return (
                  <TableRow
                    key={row._id}
                    hover
                    onClick={() =>
                      this.props.history.push(`/app/budgets/${row._id}`)
                    }
                    className={classes.row}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: row?.name ? "#000" : "#bbb" }}
                    >
                      {row?.name || "Não informado"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {`${row.period} ${pluralize("mês", row.period)}`}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {price}
                    </TableCell>

                    <TableCell>
                      <Moment
                        format="DD/MM/YYYY"
                        date={new Date(row.createdAt)}
                      ></Moment>
                    </TableCell>
                    <TableCell>
                      {row.status == "active" ? "Ativo" : "Inativo"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={cont}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Página anterior",
            }}
            nextIconButtonProps={{
              "aria-label": "Próxima página",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </ContentContainer>
      </>
    );
  }
}

ListBudgets.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    cursor: "pointer",
  },
  fab: {
    margin: 0,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade("#ececec", 0.35),
    "&:hover": {
      backgroundColor: fade("#ececec", 0.8),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    marginLeft: theme.spacing.unit * 1,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 5,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
});

export default withStyles(styles)(ListBudgets);
