import React from "react";
import { Link } from "react-router-dom";

export const HeaderBadge = ({ to, count = 0, text, color = "#bbb" }) => {
  if (!count) return <></>;

  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 6,
          color,
          borderRadius: 16,
          fontSize: 12,
          lineHeight: 1,
          backgroundColor: color + "22",
        }}
      >
        <span
          style={{
            backgroundColor: color,
            color: "#fff",
            minWidth: 28,
            padding: `2px 4px`,
            height: 28,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            lineHeight: 1,
            fontWeight: 700,
          }}
        >
          {String(count)}
        </span>
        <span
          style={{
            color,
            textTransform: "uppercase",
            fontSize: 12,
            paddingRight: 12,
            fontWeight: 500,
          }}
        >
          {text}
        </span>
      </div>
    </Link>
  );
};
