import { Box, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BsBarChart } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { FaRegHandshake } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { IoMegaphoneOutline, IoSettingsOutline } from "react-icons/io5";
import { LiaCouchSolid, LiaToolsSolid } from "react-icons/lia";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { PiArticleMediumDuotone, PiWarehouseDuotone } from "react-icons/pi";
import { Link } from "react-router-dom";
import { Colors } from "../../styles/Colors";
import { handleOpenDropDown, handlePath } from "./functions/leftMenu";
import { setDropdownAfterReloaded } from "./utils/leftMenu";

const styles = (theme) => ({
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: Colors.darkGreen,
    backgroundColor: "#fff",
  },
  toolbar: theme.mixins.toolbar,
  link: {
    textDecoration: "none",
    color: "#09110b",
  },
});

const MenuItemText = ({ text = "", style = {}, parent = false }) => (
  <Typography
    style={{
      fontSize: 15,
      fontWeight: parent ? "400" : "400",
      color: "#688284",
      flex: 1,
      ...style,
    }}
  >
    {text}
  </Typography>
);

function LeftMenu(props) {
  const { classes } = props;
  const permissions = localStorage.getItem("user");

  const [openDropDown, setOpenDropDown] = useState(null);
  const [path, usePath] = useState("");

  useEffect(() => {
    setDropdownAfterReloaded({ setOpenDropDown });
  }, []);

  useEffect(() => {
    handlePath(usePath);
  }, [window.location.pathname]);

  return (
    <Box style={{ backgroundColor: "#fff", position: "relative" }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: `20px`,
        }}
      >
        <Link to="/app">
          <img
            src="/images/logo-mobile.svg"
            width={110}
            height={100}
            alt=""
          ></img>
        </Link>
      </Box>
      <List
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          padding: `6px 28px`,
        }}
      >
        {permissions.includes("Administrador") && (
          <Link to="/app/users" className={classes.link}>
            <ListItem
              selected={path === "/app/users"}
              button
              style={{
                display: "flex",
                gap: 12,
                color: "#444",
                borderRadius: 40,
              }}
            >
              <FiUsers size={20} color="#688284" />
              <MenuItemText parent text="Usuários" />
            </ListItem>
          </Link>
        )}
        {(permissions.includes("Administrador") ||
          permissions.includes("MarketingPartner")) && (
          <Link to="/app/carts" className={classes.link}>
            <ListItem
              selected={path === "/app/carts"}
              button
              style={{
                display: "flex",
                gap: 12,
                color: "#444",
                borderRadius: 40,
              }}
            >
              <AiOutlineShoppingCart size={20} color="#688284" />
              <MenuItemText parent text="Carrinhos" />
            </ListItem>
          </Link>
        )}
        {permissions.includes("Administrador") && (
          <Link to="/app/stock" className={classes.link}>
            <ListItem
              selected={path === "/app/stock"}
              button
              style={{
                display: "flex",
                gap: 12,
                color: "#444",
                borderRadius: 40,
              }}
            >
              <PiWarehouseDuotone size={20} color="#688284" />
              <MenuItemText parent text="Estoque" />
            </ListItem>
          </Link>
        )}
        {permissions.includes("Financeiro") && (
          <>
            <Link to="/app/tuimbox/reports" className={classes.link}>
              <ListItem
                selected={path === "/app/tuimbox/reports"}
                button
                style={{
                  display: "flex",
                  gap: 12,
                  color: "#444",
                  borderRadius: 40,
                }}
              >
                <MenuItemText text="Faturas" />
              </ListItem>
            </Link>

            <Link to="/app/location/blacklist" className={classes.link}>
              <ListItem
                selected={path === "/app/location/blacklist"}
                button
                style={{
                  display: "flex",
                  gap: 12,
                  color: "#444",
                  borderRadius: 40,
                }}
              >
                <MenuItemText text="Área de Risco" />
              </ListItem>
            </Link>

            <Link to="/app/block/blockClientList" className={classes.link}>
              <ListItem
                selected={path === "/app/block/blockClientList"}
                button
                style={{
                  display: "flex",
                  gap: 12,
                  color: "#444",
                  borderRadius: 40,
                }}
              >
                <MenuItemText text="Bloqueio de Clientes" />
              </ListItem>
            </Link>
          </>
        )}
        {permissions.includes("Administrador") ||
        permissions.includes("Financeiro") ? (
          <>
            <ListItem
              onClick={() =>
                handleOpenDropDown({ id: 2, openDropDown, setOpenDropDown })
              }
              button
              style={{
                display: "flex",
                gap: 12,
                color: "#444",
                borderRadius: 40,
              }}
            >
              <CgProfile size={20} color="#688284" />
              <MenuItemText parent text="Clientes" />
              {openDropDown === 2 ? (
                <MdKeyboardArrowUp size={20} color="#68828477" />
              ) : (
                <MdKeyboardArrowDown size={20} color="#68828477" />
              )}
            </ListItem>

            {openDropDown === 2 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  marginBottom: 16,
                }}
              >
                <Link to="/app/clients" className={classes.link}>
                  <ListItem
                    selected={path === "/app/clients"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    {/* <RiProfileLine size={20} color="#688284" /> */}
                    <MenuItemText text="Cadastros" />
                  </ListItem>
                </Link>
                <Link to="/app/contracts" className={classes.link}>
                  <ListItem
                    selected={path === "/app/contracts"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    {/* <HiOutlineDocumentDuplicate size={20} color="#688284" /> */}

                    <MenuItemText text="Contratos" />
                  </ListItem>
                </Link>

                <Link to="/app/listBugets" className={classes.link}>
                  <ListItem
                    selected={path === "/app/listBugets"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Orçamentos" />
                  </ListItem>
                </Link>
                <Link to="/app/quotations" className={classes.link}>
                  <ListItem
                    selected={path === "/app/quotations"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Cotações" />
                  </ListItem>
                </Link>

                <Link to="/app/warns" className={classes.link}>
                  <ListItem
                    selected={path === "/app/warns"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Solicitações de Avisos" />
                  </ListItem>
                </Link>
                <Link to="/app/block/blockClientList" className={classes.link}>
                  <ListItem
                    selected={path === "/app/block/blockClientList"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Clientes Bloqueados" />
                  </ListItem>
                </Link>

                {permissions.includes("DPO") && (
                  <Link
                    to="/app/clients/exercise-of-right"
                    className={classes.link}
                  >
                    <ListItem
                      selected={path === "/app/clients/exercise-of-right"}
                      button
                      style={{
                        display: "flex",
                        gap: 12,
                        color: "#444",
                        borderRadius: 40,
                      }}
                    >
                      <MenuItemText text="Exercício de Direito" />
                    </ListItem>
                  </Link>
                )}
              </div>
            )}
          </>
        ) : (
          ""
        )}

        {permissions.includes("Administrador") ? (
          <>
            <ListItem
              onClick={() =>
                handleOpenDropDown({ id: 1, openDropDown, setOpenDropDown })
              }
              button
              style={{
                display: "flex",
                gap: 12,
                color: "#444",
                borderRadius: 40,
              }}
            >
              <FaRegHandshake size={20} color="#688284" />
              <MenuItemText parent text="Parcerias" />
              {openDropDown === 1 ? (
                <MdKeyboardArrowUp size={20} color="#68828477" />
              ) : (
                <MdKeyboardArrowDown size={20} color="#68828477" />
              )}
            </ListItem>
            {openDropDown === 1 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#ffffff10",
                  marginBottom: 16,
                }}
              >
                <Link to="/app/brokers" className={classes.link}>
                  <ListItem
                    selected={path === "/app/brokers"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Corretores" />
                  </ListItem>
                </Link>

                <Link
                  to="/app/partnership/home-stagings"
                  className={classes.link}
                >
                  <ListItem
                    selected={path === "/app/partnership/home-stagings"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Home Stagers" />
                  </ListItem>
                </Link>
                <Link to="/app/partners" className={classes.link}>
                  <ListItem
                    selected={path === "/app/partners"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Parceiros" />
                  </ListItem>
                </Link>
                <Link to="/app/partners/buildings" className={classes.link}>
                  <ListItem
                    button
                    selected={path === "/app/partners/buildings"}
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Imóveis" />
                  </ListItem>
                </Link>
              </div>
            )}
          </>
        ) : (
          ""
        )}
        {permissions.includes("Administrador") ||
        permissions.includes("Arquiteto") ? (
          <>
            <ListItem
              onClick={() =>
                handleOpenDropDown({ id: 3, openDropDown, setOpenDropDown })
              }
              button
              style={{
                display: "flex",
                gap: 12,
                color: "#444",
                borderRadius: 40,
              }}
            >
              <LiaCouchSolid size={20} color="#688284" />
              <MenuItemText parent text="Mobiliário" />
              {openDropDown === 3 ? (
                <MdKeyboardArrowUp size={20} color="#68828477" />
              ) : (
                <MdKeyboardArrowDown size={20} color="#68828477" />
              )}
            </ListItem>
            {openDropDown === 3 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#ffffff10",
                  marginBottom: 16,
                }}
              >
                <Link to="/app/products" className={classes.link}>
                  <ListItem
                    selected={path === "/app/products"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Produtos" />
                  </ListItem>
                </Link>
                <Link to="/app/kits" className={classes.link}>
                  <ListItem
                    selected={path === "/app/kits"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Kits" />
                  </ListItem>
                </Link>
                <Link to="/app/features" className={classes.link}>
                  <ListItem
                    selected={path === "/app/features"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Características" />
                  </ListItem>
                </Link>
                <Link to="/app/ambients" className={classes.link}>
                  <ListItem
                    selected={path === "/app/ambients"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Ambientes" />
                  </ListItem>
                </Link>
                <Link to="/app/styles" className={classes.link}>
                  <ListItem
                    selected={path === "/app/styles"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Estilos" />
                  </ListItem>
                </Link>
                <Link to="/app/categories" className={classes.link}>
                  <ListItem
                    selected={path === "/app/categories"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Categorias" />
                  </ListItem>
                </Link>
                <Link to="/app/units" className={classes.link}>
                  <ListItem
                    selected={path === "/app/units"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Filiais" />
                  </ListItem>
                </Link>
                <Link to="/app/listDiscount" className={classes.link}>
                  <ListItem
                    selected={path === "/app/listDiscount"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Tabela de Preços" />
                  </ListItem>
                </Link>
                <Link to="/app/calendar" className={classes.link}>
                  <ListItem
                    selected={path === "/app/calendar"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Calendário" />
                  </ListItem>
                </Link>
              </div>
            )}
          </>
        ) : (
          ""
        )}

        {permissions.includes("Administrador") ||
        permissions.includes("SEO") ||
        permissions.includes("MarketingPartner") ||
        permissions.includes("Marketing") ? (
          <>
            <ListItem
              onClick={() =>
                handleOpenDropDown({ id: 4, openDropDown, setOpenDropDown })
              }
              button
              style={{
                display: "flex",
                gap: 12,
                color: "#444",
                borderRadius: 40,
              }}
            >
              <PiArticleMediumDuotone size={20} color="#688284" />
              <MenuItemText parent text="Conteúdo" />
              {openDropDown === 4 ? (
                <MdKeyboardArrowUp size={20} color="#68828477" />
              ) : (
                <MdKeyboardArrowDown size={20} color="#68828477" />
              )}
            </ListItem>
            {openDropDown === 4 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 16,
                }}
              >
                <Link to="/app/articles" className={classes.link}>
                  <ListItem
                    selected={path === "/app/articles"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Artigos" />
                  </ListItem>
                </Link>
                <Link to="/app/press" className={classes.link}>
                  <ListItem
                    selected={path === "/app/press"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Na Mídia" />
                  </ListItem>
                </Link>
                <Link to="/app/faqs" className={classes.link}>
                  <ListItem
                    selected={path === "/app/faqs"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Faq" />
                  </ListItem>
                </Link>
                {permissions.includes("Administrador") && (
                  <Link to="/app/translations" className={classes.link}>
                    <ListItem
                      selected={path === "/app/translations"}
                      button
                      style={{
                        display: "flex",
                        gap: 12,
                        color: "#444",
                        borderRadius: 40,
                      }}
                    >
                      <MenuItemText text="Traduções" />
                    </ListItem>
                  </Link>
                )}
              </div>
            )}
          </>
        ) : (
          ""
        )}

        {permissions.includes("Administrador") ||
        permissions.includes("MarketingPartner") ? (
          <>
            <ListItem
              onClick={() =>
                handleOpenDropDown({ id: 7, openDropDown, setOpenDropDown })
              }
              button
              style={{
                display: "flex",
                gap: 12,
                color: "#444",
                borderRadius: 40,
              }}
            >
              <BsBarChart size={20} color="#688284" />
              <MenuItemText parent text="Relatório" />
              {openDropDown === 7 ? (
                <MdKeyboardArrowUp size={20} color="#68828477" />
              ) : (
                <MdKeyboardArrowDown size={20} color="#68828477" />
              )}
            </ListItem>
            {openDropDown === 7 &&
              (permissions.includes("Administrador") ||
                permissions.includes("MarketingPartner")) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#ffffff10",
                    marginBottom: 16,
                  }}
                >
                  {openDropDown === 7 &&
                    permissions.includes("Administrador") && (
                      <>
                        <Link
                          to="/app/relatorio/contract"
                          className={classes.link}
                        >
                          <ListItem
                            selected={path === "/app/relatorio/contract"}
                            button
                            style={{
                              display: "flex",
                              gap: 12,
                              color: "#444",
                              borderRadius: 40,
                            }}
                          >
                            <MenuItemText text="Contratos" />
                          </ListItem>
                        </Link>
                        <Link
                          to="/app/relatorio/products"
                          className={classes.link}
                        >
                          <ListItem
                            selected={path === "/app/relatorio/products"}
                            button
                            style={{
                              display: "flex",
                              gap: 12,
                              color: "#444",
                              borderRadius: 40,
                            }}
                          >
                            <MenuItemText text="Produtos" />
                          </ListItem>
                        </Link>
                      </>
                    )}

                  {openDropDown === 7 &&
                    (permissions.includes("Administrador") ||
                      permissions.includes("MarketingPartner")) && (
                      <>
                        <Link to="/app/report/month" className={classes.link}>
                          <ListItem
                            selected={path === "/app/report/month"}
                            button
                            style={{
                              display: "flex",
                              gap: 12,
                              color: "#444",
                              borderRadius: 40,
                            }}
                          >
                            <MenuItemText text="Mensal" />
                          </ListItem>
                        </Link>
                      </>
                    )}

                  {openDropDown === 7 &&
                    permissions.includes("Administrador") && (
                      <>
                        <Link
                          to="/app/report/category"
                          className={classes.link}
                        >
                          <ListItem
                            selected={path === "/app/report/category"}
                            button
                            style={{
                              display: "flex",
                              gap: 12,
                              color: "#444",
                              borderRadius: 40,
                            }}
                          >
                            <MenuItemText text="Categorizado" />
                          </ListItem>
                        </Link>
                        <Link to="/app/report/history" className={classes.link}>
                          <ListItem
                            selected={path === "/app/report/history"}
                            button
                            style={{
                              display: "flex",
                              gap: 12,
                              color: "#444",
                              borderRadius: 40,
                            }}
                          >
                            <MenuItemText text="Histórico" />
                          </ListItem>
                        </Link>
                        <Link
                          to="/app/report/evolution"
                          className={classes.link}
                        >
                          <ListItem
                            selected={path === "/app/report/evolution"}
                            button
                            style={{
                              display: "flex",
                              gap: 12,
                              color: "#444",
                              borderRadius: 40,
                            }}
                          >
                            <MenuItemText text="Evolutivo" />
                          </ListItem>
                        </Link>

                        {/* <ListItem button style={{ display: 'flex', gap: 12, color: '#444',  borderRadius: 40 }}>
                              <Link to='/app/report/performance/site' className={classes.link}>
                                 <MenuItemText text='Desempenho (site)' />
                              </Link>
                           </ListItem>  */}
                        <Link
                          to="/app/report/performance/partners"
                          className={classes.link}
                        >
                          <ListItem
                            selected={
                              path === "/app/report/performance/partners"
                            }
                            button
                            style={{
                              display: "flex",
                              gap: 12,
                              color: "#444",
                              borderRadius: 40,
                            }}
                          >
                            <MenuItemText text="Parceiros" />
                          </ListItem>
                        </Link>
                      </>
                    )}
                </div>
              )}
          </>
        ) : (
          ""
        )}

        {permissions.includes("Administrador") ? (
          <>
            <ListItem
              onClick={() =>
                handleOpenDropDown({ id: 6, openDropDown, setOpenDropDown })
              }
              button
              style={{
                display: "flex",
                gap: 12,
                color: "#444",
                borderRadius: 40,
              }}
            >
              <IoMegaphoneOutline size={20} color="#688284" />
              <MenuItemText parent text="Marketing" />
              {openDropDown === 6 ? (
                <MdKeyboardArrowUp size={20} color="#68828477" />
              ) : (
                <MdKeyboardArrowDown size={20} color="#68828477" />
              )}
            </ListItem>
            {openDropDown === 6 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 16,
                }}
              >
                <Link to="/app/marketing/adinvestment" className={classes.link}>
                  <ListItem
                    selected={path === "/app/marketing/adinvestment"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Investimento" />
                  </ListItem>
                </Link>
              </div>
            )}
          </>
        ) : (
          <></>
        )}

        {permissions.includes("Administrador") ? (
          <>
            <ListItem
              onClick={() =>
                handleOpenDropDown({ id: 5, openDropDown, setOpenDropDown })
              }
              button
              style={{
                display: "flex",
                gap: 12,
                color: "#444",
                borderRadius: 40,
              }}
            >
              <LiaToolsSolid size={20} color="#688284" />
              <MenuItemText parent text="Ferramentas" />
              {openDropDown === 5 ? (
                <MdKeyboardArrowUp size={20} color="#68828477" />
              ) : (
                <MdKeyboardArrowDown size={20} color="#68828477" />
              )}
            </ListItem>
            {openDropDown === 5 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#ffffff10",
                  marginBottom: 16,
                }}
              >
                <Link to="/app/location/blacklist" className={classes.link}>
                  <ListItem
                    selected={path === "/app/location/blacklist"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Área de Risco" />
                  </ListItem>
                </Link>
                <Link to="/app/coupons" className={classes.link}>
                  <ListItem
                    selected={path === "/app/coupons"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Cupons de Desconto" />
                  </ListItem>
                </Link>
                <Link to="/app/seos" className={classes.link}>
                  <ListItem
                    selected={path === "/app/seos"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="SEO" />
                  </ListItem>
                </Link>
                <Link to="/app/logs" className={classes.link}>
                  <ListItem
                    selected={path === "/app/logs"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Logs" />
                  </ListItem>
                </Link>
                <Link to="/app/tasks" className={classes.link}>
                  <ListItem
                    selected={path === "/app/tasks"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Chamados" />
                  </ListItem>
                </Link>
                <Link to="/app/location" className={classes.link}>
                  <ListItem
                    selected={path === "/app/location"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Cidades" />
                  </ListItem>
                </Link>
                <Link to="/app/import/products" className={classes.link}>
                  <ListItem
                    selected={path === "/app/import/products"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Importar Produtos" />
                  </ListItem>
                </Link>
                <Link to="/app/import/productsUpdate" className={classes.link}>
                  <ListItem
                    selected={path === "/app/import/productsUpdate"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Atualizar Produtos" />
                  </ListItem>
                </Link>
              </div>
            )}
          </>
        ) : (
          <></>
        )}

        {permissions.includes("Administrador") ? (
          <div>
            <ListItem
              onClick={() =>
                handleOpenDropDown({ id: 8, openDropDown, setOpenDropDown })
              }
              button
              style={{
                display: "flex",
                gap: 12,
                color: "#444",
                borderRadius: 40,
              }}
            >
              <IoSettingsOutline size={20} color="#688284" />
              <MenuItemText parent text="Configurações" />
              {openDropDown === 8 ? (
                <MdKeyboardArrowUp size={20} color="#68828477" />
              ) : (
                <MdKeyboardArrowDown size={20} color="#68828477" />
              )}
            </ListItem>
            {openDropDown === 8 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 16,
                }}
              >
                <Link to="/app/settings/contract" className={classes.link}>
                  <ListItem
                    selected={path === "/app/settings/contract"}
                    button
                    style={{
                      display: "flex",
                      gap: 12,
                      color: "#444",
                      borderRadius: 40,
                    }}
                  >
                    <MenuItemText text="Contrato" />
                  </ListItem>
                </Link>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}

        {permissions.includes("Administrador") ? (
          <Link to="/app/webhooksap" className={classes.link}>
            <ListItem
              selected={path === "/app/webhooksap"}
              button
              style={{
                display: "flex",
                gap: 12,
                color: "#444",
                borderRadius: 40,
              }}
            >
              <BsBarChart size={20} color="#688284" />
              <MenuItemText parent text="Webhook SAP" />
            </ListItem>
          </Link>
          ) : (
          <></>
        )}
      </List>
    </Box>
  );
}

LeftMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeftMenu);
