import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";

const TextField = (props) => (
  <div className={props.classes.container} style={props.style}>
    <span className={props.classes.label}>{props.title}</span>
    <input
      type={props.type || "text"}
      min={props.min}
      max={props.max}
      className={props.classes.field}
      value={props.value}
      onChange={(e) => (props.onChange ? props.onChange(e) : false)}
      onBlur={(e) => (props.onBlur ? props.onBlur(e) : false)}
      name={props.name}
      placeholder={props.placeholder}
      disabled={props?.disabled || false}
      style={{
        ...props.inputStyle,
        color: props?.disabled ? "#999" : "initial",
        opacity: props?.disabled ? 0.6 : 1,
      }}
      {...props}
    />
  </div>
);

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: 4,
  },
  field: {
    fontSize: 14,
    padding: 16,
    border: `1px solid #eee`,
    backgroundColor: "#f7f7f7",
    borderRadius: 12,
    fontFamily: "Ubuntu",
    fontSize: 14,
    color: "#444",
  },
  label: {
    fontSize: 14,
    fontWeight: "500",
    color: "#444",
  },
});

export default withStyles(styles)(TextField);
