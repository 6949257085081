import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { fade } from "@material-ui/core/styles/colorManipulator";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import Loading from "../utils/Loading";

class ListDiscount extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      classes,
      data: [],
      searchTerm: "",
    };
  }

  componentDidMount = async () => {
    const _this = this;

    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_URL}/v2/admin/price-list`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        _this.setState({
          data: response.data.priceLists,
        });
      })
      .catch(() => {
        alert("Houve um problema ao carregar os dados.");
      });
  };

  columns = [
    {
      id: "name",
      disablePadding: false,
      label: "Nome",
    },
    {
      id: "createdAt",
      disablePadding: false,
      label: "Criada em",
    },
  ];

  render() {
    const { classes, data } = this.state;

    return (
      <>
        <Loading loading={false} />
        <ContentHeader
          title="Tabelas de Desconto"
          buttons={[
            {
              text: "Nova",
              onClick: () => this.props.history.push(`/app/editDiscount/`),
            },
          ]}
        />

        <ContentContainer>
          <Box style={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {this.columns.map(
                    (row) => (
                      <TableCell
                        key={row.id}
                        align={row.align}
                        padding={row.disablePadding ? "none" : "default"}
                      >
                        {row.label}
                      </TableCell>
                    ),
                    this
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row) => {
                  return (
                    <TableRow
                      key={row._id}
                      hover
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(`/app/editDiscount/${row._id}`, "_blank");
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="th">{row.createdAt}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </ContentContainer>
      </>
    );
  }
}

ListDiscount.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    cursor: "pointer",
  },
});

export default withStyles(styles)(ListDiscount);
