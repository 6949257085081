import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import React, { useState } from "react";
import { pluralize } from "../../functions";
import { useFetch } from "../../hooks/useFetch";
import { Colors } from "../../styles/Colors";
import { HeaderBadge } from "../headerBadges";
import EditPasswordDialog from "../user/EditPasswordDialog";

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  link: {
    textDecoration: "none",
    color: "#FFF",
  },
  grow: {
    flexGrow: 1,
  },
});

const Header = ({ history }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditPasswordDialogOpen, setIsEditPasswordDialogOpen] =
    useState(false);
  const isMenuOpen = Boolean(anchorEl);
  // const history = useHistory();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditProfile = () => {
    setAnchorEl(null);
    history.push("/app/profile");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tuimAdmin.user");
    localStorage.removeItem("_id");
    history.push("/");
  };

  const handleEditPassword = () => {
    setAnchorEl(null);
    setIsEditPasswordDialogOpen(true);
  };

  const { data: warns } = useFetch({
    url: `/v2/admin/warnings/count`,
  });
  const totalWarnsText = `${pluralize("Aviso", warns.count)}`;

  const { data: stock } = useFetch({
    url: `/v2/admin/stock-alert/count`,
  });
  const totalStockText = `${pluralize("Alerta", stock.total)} de estoque`;

  const renderMenu = () => {
    return (
      <div>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEditProfile}>Meus Dados</MenuItem>
          <MenuItem onClick={handleEditPassword}>Alterar Senha</MenuItem>
          <MenuItem onClick={handleLogout}>Deslogar</MenuItem>
        </Menu>
        <EditPasswordDialog
          open={isEditPasswordDialogOpen}
          onClose={() => setIsEditPasswordDialogOpen(false)}
        />
      </div>
    );
  };

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: `0px 48px`,
        backgroundColor: "#212f3107",
        borderBottom: `1px solid #e5e5e5`,
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
        <HeaderBadge
          to="/app/stock"
          count={stock?.total}
          text={totalStockText}
          color="#d84d38"
        />
        <HeaderBadge
          to="/app/warns"
          count={warns?.count}
          text={totalWarnsText}
          color="#f0a649"
        />
      </div>
      {renderMenu()}
      <IconButton
        aria-owns={isMenuOpen ? "material-appbar" : undefined}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color={Colors.darkGreen}
      >
        <AccountCircle />
      </IconButton>
    </Box>
  );
};

export default withStyles(styles)(Header);
