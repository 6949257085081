import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import Numeral from "numeral";
import "numeral/locales/pt-br";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import { getPriceByProductItem } from "../../functions/calcCartPrice/calcCartPrice";
import { formatter, pluralize } from "../../functions/index";
import { Colors } from "../../styles/Colors";
import { TuimButton } from "../layout/Button";
import { ContentContainer } from "../layout/components/contentContainer/index";
import ContentHeader from "../layout/components/contentHeader/index";
import { Rows } from "../layout/components/rows";
import { Text } from "../layout/components/text";
import AlertDialog from "../utils/AlertDialog";
import Loading from "../utils/Loading";
import { NewBudgetPdf } from "./NewBudgetPdf";

Numeral.locale("pt-br");

class Budget extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      classes,
      data: {
        _id,
        name: "",
        description: "",
        price: 0,
        priceDelivery: 0,
        active: 0,
        products: [],
        delivery_date: "",
        address: {},
        status: "",
      },
      bills: [],
      products: [],
      saving: false,
      success: false,
      error: null,
      anchorEl: null,
      open: false,
      openMenuOptions: false,
      showAlert: false,
      showCancelContractAlert: false,
      showReactivateContractAlert: false,
      selected: [],
      loading: true,
      copyButtonText: "Copiar",
    };
    const token = localStorage.getItem("token");
    this.headers = {
      headers: { Authorization: "bearer " + token },
    };

    this.onChange = this.onChange.bind(this);
    this.onCancelContractPress = this.onCancelContractPress.bind(this);
    this.onReactivateContractPress = this.onReactivateContractPress.bind(this);
    this.getRowsSelectedModal = this.getRowsSelectedModal.bind(this);
  }

  componentDidMount = async () => {
    const { _id } = this.state.data;

    if (!_id) {
      this.setState({ loading: false });
      return;
    }

    const _this = this;

    await axios
      .get(`${process.env.REACT_APP_API_URL}/budget/${_id}`, this.headers)
      .then((response) => {
        const { data } = response;
        const products = Array.isArray(data.products)
          ? data.products
          : Object.values(data.products);
        console.log(data);
        _this.setState({
          data,
          serializedData: {
            name: data.name,
            email: data.email,
            status: data.status,
            active: data.active,
            cep: data?.cep || data?.location?.zipCode,
            coupon: data?.coupon,
            period: data?.period,
            priceWithoutDiscount:
              data?.price?.priceWithoutDiscount ||
              data?.costs?.originalSubscription,
            price:
              data?.price?.priceWithDiscount ||
              data?.price?.priceWithoutDiscount ||
              data?.costs?.subscription,
            priceDelivery:
              data?.priceDelivery?.priceDeliveryWithDiscount ||
              data?.priceDelivery?.priceDeliveryWithoutDiscount ||
              data?.costs?.deliveryFee,
            products,
            productsQtd: products?.length || 0,
          },
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onChange = (e) => {
    const { data } = this.state;
    let value;

    if (typeof e.target.value == "object") {
      value = e.target.value.join(",");
    } else {
      value = e.target.value;
    }

    this.setState({
      data: {
        ...data,
        [e.target.name]: value,
      },
      success: false,
    });
  };

  deleteBudget = async () => {
    const { data } = this.state;
    const { _id } = data;

    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}/deleteBudget/${_id}`,
        this.headers
      )
      .then((response) => {
        this.props.history.goBack();
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  onCancelPress = () => {
    this.props.history.goBack();
  };
  onCancelContractPress = () => {
    this.setState({
      anchorEl: null,
      open: false,
      showCancelContractAlert: true,
    });
  };

  onReactivateContractPress = () => {
    this.setState({
      anchorEl: null,
      open: false,
      showReactivateContractAlert: true,
    });
  };
  getProdutosClickOpen = () => {
    this.setState({ open: true });

    this.getProducts();
  };

  getProducts = async () => {
    const _this = this;

    if (_this.state.products == "" || _this.state.products.length <= 1) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/product/`, this.headers)
        .then((response) => {
          const { data } = response;

          _this.setState({
            products: data,
          });
        });
    }
  };

  getRowsSelectedModal(_selected) {
    const _this = this;

    _this.state.data.products = _selected;

    _this.setState({
      data: _this.state.data,
      open: false,
    });
  }

  deleteProduct = (productDelected) => () => {
    const { data, selected } = this.state;
    const productsToDelete = data.products.indexOf(productDelected);
    data.products.splice(productsToDelete, 1);
    selected.splice(productsToDelete, 1);

    this.setState({
      data,
      selected,
    });
  };

  arredondar = (nr) => {
    return Math.round(nr, -1).toFixed(2);
  };

  sendBudget = async () => {
    try {
      this.setState({ loading: true });
      const { serializedData } = this.state;

      let produtosCarrinho = `<table width='100%' style='color: #999999;margin-top: 40;padding-bottom: 40px'; align='left;padding-left: 20px;margin-left: 20px' >
          <thead>
          <tr style='color: #999999;text-align: left'>
            <th></th>
            <th>Nome</th>
            <th>Valor</th>
            <th>Qtd</th>
          </tr>
          </thead>
          <tbody>`;

      for (const product of serializedData.products) {
        produtosCarrinho += `<tr style='text-align: left'>
                       <td><img src="${
                         product.urlImg
                       }" width="64" height="64" style='width: 64px;height: 64px' /></td>
                       <td>${product.name}</td>
                       <td>${formatter.currency(
                         product.price * product.qtd
                       )}</td>
                       <td>${product.qtd}</td>
                   </tr>`;
      }

      produtosCarrinho += `</tbody></table>`;

      if (produtosCarrinho.length >= 3900) {
        produtosCarrinho = `<table width='100%' style='color: #999999;margin-top: 40;padding-bottom: 40px'; align='left;padding-left: 20px;margin-left: 20px' ><thead><tr style='color: #999999;text-align: left'><th>Nome</th><th>Valor</th><th>Qtd</th></tr></thead><tbody>`;

        for (const product of serializedData.products) {
          produtosCarrinho += `<tr><td>${
            product.name
          }</td><td>${formatter.currency(
            product.price * product.qtd
          )}}</td><td>${product.qtd}</td></tr>`;
        }
      }

      if (produtosCarrinho.length >= 3800) {
        alert("Orçamento muito grande, ele pode chegar incompleto ao cliente!");
      }

      await axios.post(`${process.env.REACT_APP_API_URL}/createLead`, {
        conversion_identifier: "Orcamento - Site Tuim",
        email: serializedData.email,
        name: serializedData.name,
        cf_period:
          serializedData.period > 1
            ? serializedData.period + " Meses"
            : serializedData.period + " Mês",
        cf_price_month: String(serializedData.price),
        cf_delivery_price: String(serializedData.deliveryPrice),
        cf_product_list_html: produtosCarrinho,
        cf_budget_id: serializedData._id,
        cf_address_cep: serializedData.cep,
      });
      alert(
        "Tudo certo!\n\nSolicitação de disparo de e-mail enviada do RD Station.\n\nCaso haja qualquer problema com o recebimento do e-mail por parte do cliente, por favor consulte o lead no painel RD Station."
      );
    } catch (error) {
      alert("Houve um problema ao enviar o orçamento.");
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleUpdateCopyButtonText = () => {
    if (this.state.copyButtonText === "Copiado!") return;
    this.setState({ copyButtonText: "Copiado!" });
  };

  render() {
    const {
      classes,
      anchorEl,
      showCancelContractAlert,
      data,
      openMenuOptions,
      loading,
      copyButtonText,
      serializedData = {},
    } = this.state;

    const budgetLink = `https://www.tuim.com.br/carrinho?orcamento=${data._id}`;

    return (
      <>
        <Loading loading={loading} />
        <ContentHeader title={data.name || ""} subtitle={data.email || ""} />
        <Box style={{ display: "flex", gap: 24 }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 24,
              minWidth: 320,
            }}
          >
            <ContentContainer label="Destalhes do orçamento">
              <Rows
                data={[
                  {
                    label: "Período",
                    description: `${data.period} ${pluralize(
                      "mês",
                      data.period
                    )}`,
                  },
                  { label: "CEP", description: serializedData.cep },
                  ...(serializedData.coupon?.code
                    ? [
                        {
                          label: "Cupom",
                          description: `${serializedData.coupon.code}${
                            serializedData.coupon?.cartDiscount?.percentage
                              ? ` (${serializedData.coupon.cartDiscount.percentage}%)`
                              : ""
                          }`,
                        },
                      ]
                    : []),
                  {
                    label: "Criado em",
                    description: formatter.date(data.createdAt, "short"),
                  },
                  ...(serializedData.priceDelivery
                    ? [
                        {
                          label: "Entrega",
                          description: `${formatter.currency(
                            serializedData.priceDelivery
                          )}`,
                        },
                      ]
                    : []),
                  {
                    label: "Total",
                    description: `${formatter.currency(
                      serializedData.price
                    )}/mês`,
                  },
                ]}
              />
            </ContentContainer>
            <ContentContainer label="Link de acesso" gap={12}>
              <TuimButton
                fullWidth
                text="Enviar ao cliente"
                onClick={this.sendBudget}
              />
              <ReactToPrint
                trigger={() => {
                  return <TuimButton fullWidth text="Baixar PDF" tertiary />;
                }}
                content={() => this.componentRef}
                documentTitle="Budget"
                pageStyle={`
                  @media print {
                    div[data-print="true"] {
                      display: block !important;
                    }

                    @page {
                      size: auto;
                      margin: 0;
                    }
                  }
                `}
              />
              <TuimButton
                fullWidth
                tertiary
                text={copyButtonText}
                onClick={() =>
                  navigator.clipboard
                    .writeText(budgetLink)
                    .then(this.handleUpdateCopyButtonText)
                }
              />
              <TuimButton
                fullWidth
                tertiary
                text="Abrir"
                onClick={() => window.open(budgetLink, "_blank")}
              />
            </ContentContainer>
          </Box>
          <ContentContainer
            label={`Produtos (${serializedData.productsQtd})`}
            fullWidth
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 28,
              }}
            >
              <Table
                style={{ minWidth: 662, flex: 1 }}
                aria-label="simple table"
              >
                <TableBody>
                  {serializedData.products?.map((product) => {
                    const productPrice = data?.costs
                      ? product.price
                      : getPriceByProductItem(
                          product,
                          data.period,
                          product.qtd
                        );
                    return (
                      <TableRow key={product._id} sx={{ margin: 0 }}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ display: "flex", flex: 1 }}
                        >
                          <Link
                            target="_blank"
                            href={`/app/product/${product._id}`}
                            style={{
                              display: "flex",
                              flex: 1,
                              textDecoration: "none",
                            }}
                          >
                            <Box style={{ display: "flex", gap: 30, flex: 1 }}>
                              <div
                                style={{
                                  backgroundImage: `url(${product.urlImg})`,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                  width: 120,
                                  height: 80,
                                  borderRadius: 8,
                                }}
                              />
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 8,
                                }}
                              >
                                <Text
                                  strong
                                  style={{ fontSize: 18 }}
                                  color="#444"
                                >
                                  {product.name}
                                </Text>
                                <Box style={{ display: "flex", gap: 10 }}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      backgroundColor: Colors.paleYellow,
                                      padding: `2px 18px`,
                                      borderRadius: 20,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text detail strong color="#444">
                                      {product.qtd}{" "}
                                      {pluralize("unidade", product.qtd)}
                                    </Text>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      backgroundColor: Colors.paleYellow,
                                      padding: `2px 18px`,
                                      borderRadius: 20,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text detail strong color="#444">
                                      {formatter.currency(productPrice)}
                                      /mês
                                    </Text>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </ContentContainer>
        </Box>
        <NewBudgetPdf
          data={serializedData}
          ref={(el) => (this.componentRef = el)}
        />

        {showCancelContractAlert && (
          <AlertDialog
            msg={`Deseja relmente cancelar este Orçamento ?`}
            title="Cancelar Orçamento"
            open={showCancelContractAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showCancelContractAlert: false,
                  });
                },
              },
              {
                label: "Confirmar",
                onPress: () => {
                  this.setState({
                    showCancelContractAlert: false,
                  });

                  this.dseleteBudget();
                },
                color: "primary",
              },
            ]}
          />
        )}
      </>
    );
  }
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    maxWidth: "450px",
    marginTop: theme.spacing.unit,
  },
  button: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  success: {
    backgroundColor: green[600],
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  titleName: {
    flex: 1,
  },
  titleBar: {
    flexDirection: "row",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  containerResumeContract: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 19,
    marginTop: 25,
    float: "left",
    width: "100%",
  },
  itemResume: {
    float: "left",
    width: "100%",
    fontSize: 22,
  },
});

Budget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Budget);
