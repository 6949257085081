import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TextField, TablePagination, InputAdornment, Dialog, DialogActions,
    DialogContent, DialogTitle, Button
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Loading from '../utils/Loading'
import ReactJson from 'react-json-view'
import ContentHeader from '../layout/components/contentHeader'
import { ContentContainer } from '../layout/components/contentContainer'


export const WebhookSAP = () => {
    const [data, setData] = useState([])
    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [openModal, setOpenModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getJsons = async () => {
            try {
                setLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/sap/contract/webhook`, {
                   headers: { 'Authorization': "Bearer " + process.env.REACT_APP_JWT_WEBHOOK_SAP }
                })
                const { data: responseData, sucess } = response.data 

                if (!sucess) {
                    alert('Erro ao buscar os dados')
                    return
                }

                const newData = responseData.map(item => ({
                    ...item,
                    jsonResponse: JSON.stringify(item.jsonResponse, null, 2),
                    createdAt: new Date(item.createdAt).toLocaleString(),
                }))
                setData(newData)

            } catch (e) {
                console.error(e)
                alert('Erro ao buscar os dados')
            } finally {
                setLoading(false)
            }
        }

        getJsons()
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    };

    const filteredData = filter !== '' ? data.filter(item => item.jsonResponse.toLowerCase().includes(filter.toLowerCase())) : data 
    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    const handleRowClick = (row) => {
        setSelectedRow(row)
        setOpenModal(true)
    };

    const handleCloseModal = () => {
        setOpenModal(false)
        setSelectedRow(null)
    };

    return (
        <>
            <Loading loading={loading} />
            <ContentHeader
                title='Webhook SAP'
                subtitle='Lista de JSONs recebidos do SAP'
            />
            <ContentContainer style={styles.container}>
                <TextField
                    label="Filtrar"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size='small'
                    placeholder='Busque por qualquer item dentro do JSON...'
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    style={styles.searchField}
                />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow style={styles.headerRow}>
                                <TableCell>ID</TableCell>
                                <TableCell>JSON Response</TableCell>
                                <TableCell>Created At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map(row => (
                                <TableRow
                                    hover 
                                    key={row._id} 
                                    style={styles.row}
                                    onClick={() => handleRowClick(row)}
                                >
                                    <TableCell>{row._id}</TableCell>
                                    <TableCell>
                                        {
                                            row.jsonResponse.length > 200
                                            ? `${row.jsonResponse.substring(0, 200)}...`
                                            : row.jsonResponse
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {row.createdAt}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

                <Dialog open={openModal} onClose={handleCloseModal}>
                    <DialogTitle>JSON</DialogTitle>
                    <DialogContent style={{ minWidth: 500 }}>
                        {selectedRow ? (
                            <ReactJson 
                                src={JSON.parse(selectedRow.jsonResponse)} 
                                theme="rjv-default"
                                collapsed={1}
                                displayDataTypes={false} 
                            />
                        ) : (
                            ''
                        )}
                    </DialogContent>
                    <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCloseModal}
                    >

                        Fechar

                    </Button>
                    </DialogActions>
                </Dialog>
            </ContentContainer>
        </>
    );
};

const styles = {
    container: {
        padding: 20,
        margin: '20px auto',
        width: '100%',
    },
    searchField: {
        marginBottom: 20,
    },
    headerRow: {
        fontWeight: 'bold',
    },
    row: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f1f1f1',
        },
    },
};
