import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { formatter, lightOrDark } from "../../functions";
import { Colors } from "../../styles/Colors";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { Text } from "../layout/components/text";
import { Alert, Selector, Stock, TextArea, TextField } from "../ui";
import { AddButton, RemoveButton } from "../ui/components/buttons";
import Dropzone from "../ui/components/upload/Dropzone";
import ImageList from "../ui/components/upload/ImageList";
import Confirm from "../utils/Confirm";
import { useFetch } from "../../hooks/useFetch";
import ProductPrices from "../product/product-prices";
import { IoClose } from "react-icons/io5";
import { TuimButton } from "../layout/Button";
import TableSelectModal from "../utils/TableSelectModal";

const DottedLine = ({ leftData, rightData }) => {
  if (!leftData || !rightData) return <></>;

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: 4,
      }}
    >
      <Text detail style={{ whiteSpace: "nowrap" }}>
        {leftData}
      </Text>
      <Box
        style={{
          borderBottom: `1px dotted #ccc`,
          width: "100%",
          height: "84%",
        }}
      />
      <Text detail>{rightData}</Text>
    </Box>
  );
};

const columnsModalProducts = [
  { id: "name", numeric: false, disablePadding: true, label: "Nome" },
  { id: "id_sap", numeric: false, disablePadding: false, label: "ID SAP" },
  {
    id: "estoque",
    numeric: false,
    disablePadding: false,
    label: "Estoque",
  },
];

const EditKit = (props) => {
  const { pathname } = props.location;
  const arrayPath = pathname.split("/");
  const _id = arrayPath[arrayPath.length - 1];

  const token = localStorage.getItem("token");
  const headers = {
    headers: { Authorization: "bearer " + token },
  };

  const descriptionRef = useRef(null);
  const priceAndActionRef = useRef(null);
  const stockRef = useRef(null);

  const [data, setData] = useState({
    images: [],
  });
  const [list_products, setList_products] = useState([]);
  const [openModalProducts, setOpenModalProducts] = useState(false);
  const [productPrices, setProductPrices] = useState({});
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [customAlert, setCustomAlert] = useState({
    open: false,
    title: "",
    msg: "",
  });
  const [selectorModal, setSelectorModal] = useState({
    open: false,
    data: [],
    callback: () => {},
  });

  const { data: productData, isLoading } = useFetch({
    url: `/v2/admin/products/${_id}`,
  });

  const { data: categories } = useFetch({ url: "/v2/admin/categories" });
  const { data: features } = useFetch({ url: "/feature" });
  const { data: units } = useFetch({ url: "/unit" });
  const { data: styles } = useFetch({ url: "/style" });
  const { data: ambients } = useFetch({ url: "/ambient" });

  const handleToggleModalProducts = () => setOpenModalProducts((prev) => !prev);

  const setItemsSelected = (itemsSelected) => {
    setData({
      ...data,
      products: itemsSelected,
    });
  };

  const getRowsSelectedModal = async (selectedProducts) => {
    setItemsSelected(selectedProducts);
    handleToggleModalProducts();
  };

  const getProdutosClickOpen = async () => {
    handleToggleModalProducts();
    await axios
      .get(`${process.env.REACT_APP_API_URL}/products/admin`, {
        params: {
          limit: 5000,
        },
        headers,
      })
      .then((response) => {
        const { data } = response;
        setList_products(data.data);
      });
  };

  const onCheckChange = (name) => (event) => {
    if (!data?.price || data?.price === "0" || !data.urlImg) {
      alert(
        "Para exibir o produto no site ele deve possuir uma imagem principal e um preço."
      );
      return;
    }

    setData({
      ...data,
      [name]: event.target.checked,
    });
  };

  const onSave = async () => {
    if (!Boolean(data?.price) || !data.urlImg) {
      return alert(
        "Para exibir o produto no site ele deve possuir uma imagem principal e um preço."
      );
    }

    if (!data._id) {
      return newProduct();
    }

    data.id_john = data?.id_sap;

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/product/${data._id}`,
        data,
        headers
      )
      .then((response) => {
        setCustomAlert({ open: true, msg: response.data.message });
        window.location.reload();
      })
      .catch((error) => {
        setCustomAlert({ open: true, msg: "Houve um problema" });
      });
  };

  const newProduct = async () => {
    const productData = data;
    productData.id_john = productData?.id_sap;
    axios
      .post(`${process.env.REACT_APP_API_URL}/addProduct`, productData, headers)
      .then((response) => {
        console.log("---> ", response.data);
        setCustomAlert({ msg: response.data.message });
        props.history.push(`/app/product/${response?.data?.data?._id}`);
      })
      .catch((error) => {
        console.log(error);
        alert("Erro ao tentar criar um novo produto.");
      });
  };

  const handleChangeProductQtd = (target) => {
    const updatedProducts = data.products;
    updatedProducts[target.index].qtd = target.qtd;
    setData({ ...data, products: updatedProducts });
  };

  const onChange = (e) => {
    let { name, value } = e.target;
    if (["width", "length", "height", "price", "discount"].includes(name)) {
      value = Number(value);
    }
    setData({ ...data, [name]: value });
  };

  // const updateCategory = async ({ removeFrom, addTo }) => {
  //   await axios.patch(
  //     `${process.env.REACT_APP_API_URL}/v2/admin/product-categories/${addTo}?removeFrom=${removeFrom}&productId=${data._id}`,
  //     {},
  //     headers
  //   );
  // };

  const handleChangeCategory = ({ target }) => {
    const { value } = target;
    // updateCategory({ removeFrom: data.category, addTo: value });
    setData({ ...data, category: value });
  };

  // const handleRemoveProductFromAmbient = async (ambient) => {
  //   await axios.delete(
  //     `${process.env.REACT_APP_API_URL}/v2/admin/product-ambients/${ambient}?productId=${data._id}`,
  //     headers
  //   );
  // };

  // const handlePushProductIntoAmbient = async (ambient) => {
  //   await axios.post(
  //     `${process.env.REACT_APP_API_URL}/v2/admin/product-ambients/${ambient}?productId=${data._id}`,
  //     {},
  //     headers
  //   );
  // };

  const onAddColor = () => {
    let { colors } = data;
    colors.push({ color: "#FFF" });
    setData({ ...data, colors });
  };

  const onRemoveColor = (index) => {
    let { colors } = data;
    colors.splice(index, 1);
    setData({ ...data, colors });
  };

  const onChangeColor = (e, index) => {
    let { colors } = data;
    colors[index].color = e.target.value;
    setData({ ...data, colors });
  };

  const onAddFeature = (item) => {
    let { features } = data;
    features.push({ title: item.label });
    setData({ ...data, features });
  };

  const removeFeature = (index) => {
    let { features } = data;
    features.splice(index, 1);
    setData({ ...data, features });
  };

  const onAddUnit = async (item) => {
    let { units } = data;
    units.push({ title: item.value });
    setData({ ...data, units });
  };

  const removeUnit = (index) => {
    let { units } = data;
    units.splice(index, 1);
    setData({ ...data, units });
  };

  const onAddAmbient = (item) => {
    let { ambients } = data;
    ambients.push({ title: item.label });
    setData({ ...data, ambients });
    // handlePushProductIntoAmbient(item.label);
  };

  const removeAmbient = (index) => {
    let { ambients } = data;
    // handleRemoveProductFromAmbient(ambients[index].title);
    ambients.splice(index, 1);
    setData({ ...data, ambients });
  };

  const onAddStyle = (item) => {
    let { styles } = data;
    styles.push({ title: item.label });
    setData({ ...data, styles });
  };

  const removeStyle = (index) => {
    let { styles } = data;
    styles.splice(index, 1);
    setData({ ...data, styles });
  };

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const handleRemoveProduct = (productId) => {
    const updatedProducts = data.products.filter(
      (item) => item.product._id !== productId
    );
    setData({ ...data, products: updatedProducts });
  };

  const onDropFiles = async (files) => {
    console.log({ files });
    const uploadedFiles = files.map((file) => ({
      file,
      id: getRandomInt(1, 999),
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    console.log({ processUpload });
    uploadedFiles.forEach(processUpload);
  };

  const processUpload = async (uploadedFile) => {
    // setImages((prev) => [...prev, uploadedFile]);
    console.log({ uploadedFile });
    setData((prev) => ({ ...prev, images: [...prev.images, uploadedFile] }));

    // setData({
    //   ...data,
    //   images: [data?.images, uploadedFile],
    // });
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);
    axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, data, headers)
      .then((response) => {
        const { data } = response;
        console.log({ data });
        updateFile(uploadedFile.id, data);
      })
      .catch((error) => console.log("error", error));
  };

  const updateFile = (id, item) => {
    // setImages((prev) =>
    //   prev.map((image) => {
    //     if (image.id !== id) return image;
    //     return { ...image, ...data, uploaded: true };
    //   })
    // );
    console.log(5);
    console.log({ item });
    console.log({ data });
    setData((prevData) => {
      const newImages =
        prevData?.images && prevData?.images.length > 0
          ? prevData?.images.map((image) => {
              if (image.id !== id) return image;
              return { ...image, ...item, uploaded: true };
            })
          : [{ ...item, uploaded: true }];

      return {
        ...prevData,
        images: newImages,
      };
    });
  };

  const onDeleteImage = (id) => {
    // setImages((prev) => prev.filter((item) => item.id !== id));
    setData((prev) => ({
      ...prev,
      images: prev.images.filter((item) => item.id !== id),
    }));
  };

  const onDeletePress = async () => {
    const { _id } = data;

    axios
      .delete(`${process.env.REACT_APP_API_URL}/product/${_id}`, headers)
      .then((response) => {
        props.history.push(`/app/products/`);
      });
  };

  // const onDropPdf = (files) => {
  //   const uploadedFiles = files.map((file) => ({
  //     file,
  //     id: uniqueId(),
  //     name: file.name,
  //     preview: URL.createObjectURL(file),
  //     progress: 0,
  //     uploaded: false,
  //     error: false,
  //     url: null,
  //   }));
  //   uploadedFiles.forEach(processUploadPdf, true);
  // };

  // const processUploadPdf = async (uploadedFile, mainImg = true) => {
  //   const data = new FormData();
  //   data.append("file", uploadedFile.file, uploadedFile.name);

  //   axios
  //     .post(`${process.env.REACT_APP_API_URL}/upload`, data, headers)
  //     .then((response) => {
  //       const { data } = response;
  //       setData({ ...data, manual: data.url });
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  useEffect(() => {
    if (!productData?._id) return;
    setData(productData);
  }, [productData]);

  const imageContainerHeight = descriptionRef?.current?.clientHeight || 0;

  const originalPrice =
    data?.products?.length > 0
      ? data.products.reduce(
          (prev, cur) => prev + cur.product.price * cur.qtd,
          0
        )
      : 0;
  const discountAmount = Math.ceil(originalPrice * (data.discount / 100));
  const finalPrice = originalPrice - discountAmount;

  if (isLoading) return <p>Carregando...</p>;

  return (
    <>
      {customAlert.open && (
        <Alert open={true} title={customAlert.title} msg={customAlert.msg} />
      )}
      <Confirm
        open={isConfirmOpen}
        title="Exclusão de Produto"
        text="Deseja realmente excluir este produto?"
        onDeletePress={onDeletePress}
        onClose={() => setIsConfirmOpen(false)}
      />
      <ContentHeader
        title={data?.name || "Novo Produto"}
        subtitle={
          data?.lastUpdatedBy?.name
            ? `Atualizado por ${data.lastUpdatedBy.name} em ${formatter.date(
                data?.updatedAt,
                "short"
              )}`
            : ``
        }
        buttons={[
          { text: "Salvar", onClick: onSave },
          {
            text: "Excluir",
            destructive: true,
            onClick: () => setIsConfirmOpen(true),
          },
        ]}
      />
      <Box style={{ display: "flex", gap: 24, flex: 1 }}>
        <Box ref={descriptionRef} style={{ display: "flex", flex: 1 }}>
          <ContentContainer label="Nome e descrição" fullWidth>
            <TextField
              value={data?.name}
              onChange={onChange}
              name="name"
              placeholder="Digite o nome do produto"
              title="Nome do Produto"
            />
            <TextArea
              value={data?.description}
              name="description"
              title="Descrição"
              onChange={onChange}
            />
            <TextArea
              value={data?.technicalDescription}
              name="technicalDescription"
              title="Descrição Técnica"
              onChange={onChange}
            />
          </ContentContainer>
        </Box>
        <ContentContainer
          label="Imagens"
          style={{ maxHeight: imageContainerHeight }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              maxWidth: 300,
              flexDirection: "column",
              margin: 15,
            }}
          >
            {data?.urlImg && (
              <div style={{ marginBottom: 15 }}>
                <Text detail>Imagem principal</Text>
                <img
                  src={data.urlImg}
                  style={{ width: "100%", borderRadius: 8 }}
                  alt="main-image"
                />
              </div>
            )}
            {data?.secondaryImage && (
              <div style={{ marginBottom: 15 }}>
                <Text detail>Imagem secundária</Text>
                <img
                  src={data.secondaryImage}
                  style={{ width: "100%", borderRadius: 8 }}
                  alt="secondary-image"
                />
              </div>
            )}
            <Dropzone
              txt="Arraste aqui as imagens do produto ou clique para selecionar os arquivos. Imagem Padrão dos produtos (800px x 610px) ou proporcional."
              onDrop={onDropFiles}
            />
            {/* <ImageList
              data={data.images}
              onDeleteImage={onDeleteImage}
              setImageDefault={(urlImg) => {
                // setState({ data: { ...data, urlImg } });
              }}
              setSecondaryImage={
                (secondaryImage) => {}
                // setState({ data: { ...data, secondaryImage } })
              }
            /> */}
            <ImageList
              data={data.images || []}
              onDeleteImage={onDeleteImage}
              setImageDefault={(urlImg) => setData({ ...data, urlImg })}
              setSecondaryImage={
                (secondaryImage) => setData({ ...data, secondaryImage })
                // setSecondaryImage(secondaryImage)
              }
            />
          </div>
        </ContentContainer>
      </Box>

      <Box style={{ display: "flex", gap: 24 }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 24,
            width: 500,
          }}
          ref={priceAndActionRef}
        >
          <ContentContainer label="Preço">
            <Text
              style={{
                textAlign: "center",
                color: Colors.darkGreenAlt,
                fontSize: 24,
                fontWeight: "700",
                marginTop: 20,
              }}
            >
              {formatter.currency(finalPrice)}
            </Text>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <DottedLine
                leftData="Valor original"
                rightData={formatter.currency(originalPrice)}
              />
              <DottedLine
                leftData="Desconto"
                rightData={formatter.currency(discountAmount)}
              />
            </Box>

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              <TextField
                value={data?.discount}
                onChange={onChange}
                name="discount"
                type="number"
                title="Porcentagem de desconto"
                style={{ flex: "unset" }}
              />
            </Box>
            <Text detail>Aplicada ao preço final do kit.</Text>
          </ContentContainer>
          <ContentContainer label="Características" fullWidth>
            <FormControl style={{ minWidth: 120, flex: 1 }}>
              <InputLabel id="category-select-label">Categoria</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={data?.category}
                name="category"
                onChange={handleChangeCategory}
              >
                {categories.map((item) => {
                  return (
                    <MenuItem key={`category_${item._id}`} value={item.title}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <TextField
                value={data?.width}
                onChange={onChange}
                name="width"
                placeholder="Largura"
                title="Largura (cm)"
                style={{ maxWidth: 120 }}
              />
              <div style={{ marginBottom: 15, padding: 10 }}>X</div>
              <TextField
                value={data?.height}
                onChange={onChange}
                name="height"
                placeholder="Altura"
                title="Altura (cm)"
                style={{ maxWidth: 120 }}
              />
              <div style={{ marginBottom: 15, padding: 10 }}>X</div>
              <TextField
                value={data?.length}
                onChange={onChange}
                name="length"
                placeholder="Profundidade"
                title="Profundidade (cm)"
                style={{ maxWidth: 120 }}
              />
            </div>
            <div>
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "500",
                  marginBottom: 5,
                }}
              >
                Cor
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {data?.colors?.map((item, index) => (
                  <div
                    key={`${index}_${item.color}`}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: 20,
                      marginBottom: 20,
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        height: 30,
                        width: 70,
                        fontSize: 14,
                        fontWeight: "600",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "gray",
                        backgroundColor: item.color,
                        color:
                          lightOrDark(item.color) == "light" ? "#000" : "#FFF",
                      }}
                      value={item.color}
                      onChange={(e) => onChangeColor(e, index)}
                      name="color"
                    />
                    <RemoveButton
                      onClick={() => onRemoveColor(index)}
                      style={{ marginLeft: -5, marginTop: -5 }}
                    />
                  </div>
                ))}
                <AddButton text="+ Cor" onClick={onAddColor} />
              </div>
            </div>

            <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text strong>Características</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                {data?.features != null
                  ? data?.features?.map((item, index) => (
                      <div
                        key={`${item.title}_listing_${index}`}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginRight: 15,
                        }}
                      >
                        <div
                          style={{
                            borderColor: "gray",
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderRadius: 5,
                            padding: 7,
                            fontSize: 12,
                          }}
                        >
                          {item.title}
                        </div>
                        <RemoveButton onClick={() => removeFeature(index)} />
                      </div>
                    ))
                  : ""}
                <AddButton
                  text="+ Característica"
                  onClick={() => {
                    setSelectorModal({
                      open: true,
                      data: features.map((item) => item.title),
                      callback: onAddFeature,
                    });
                  }}
                />
              </div>
            </Box>

            <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text strong>Disponibilidade</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                {data?.units != null &&
                  data?.units?.map((item, index) => (
                    <div
                      key={`${item.title}_listing_units_${index}`}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: 15,
                      }}
                    >
                      <div
                        style={{
                          borderColor: "gray",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: 5,
                          padding: 7,
                          fontSize: 12,
                        }}
                      >
                        {item.title}
                      </div>
                      <RemoveButton onClick={() => removeUnit(index)} />
                    </div>
                  ))}
                <AddButton
                  text="+ Filial"
                  onClick={() => {
                    setSelectorModal({
                      open: true,
                      data: units.map((item) => item.title),
                      callback: onAddUnit,
                    });
                  }}
                />
              </div>
            </Box>
            <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text strong>Estilos</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                {data?.styles != null &&
                  data?.styles?.map((item, index) => (
                    <div
                      key={`${item.title}_listing_styles_${index}`}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: 15,
                      }}
                    >
                      <div
                        style={{
                          borderColor: "gray",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: 5,
                          padding: 7,
                          fontSize: 12,
                        }}
                      >
                        {item.title}
                      </div>
                      <RemoveButton onClick={() => removeStyle(index)} />
                    </div>
                  ))}
                <AddButton
                  text="+ Estilo"
                  onClick={() => {
                    setSelectorModal({
                      open: true,
                      data: styles.map((item) => item.title),
                      callback: onAddStyle,
                    });
                  }}
                />
              </div>
            </Box>

            <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text strong>Ambientes</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                {data?.ambients != null &&
                  data?.ambients?.map((item, index) => (
                    <div
                      key={`${item.title}_listing_ambients_${index}`}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: 15,
                      }}
                    >
                      <div
                        style={{
                          borderColor: "gray",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: 5,
                          padding: 7,
                          fontSize: 12,
                        }}
                      >
                        {item.title}
                      </div>
                      <RemoveButton onClick={() => removeAmbient(index)} />
                    </div>
                  ))}
                <AddButton
                  text="+ Ambiente"
                  onClick={() => {
                    setSelectorModal({
                      open: true,
                      data: ambients.map((item) => item.title),
                      callback: onAddAmbient,
                    });
                  }}
                />
              </div>
            </Box>
            {selectorModal.open && (
              <Selector
                open={selectorModal.open}
                onSelect={(item) => {
                  selectorModal.callback(item);
                  setSelectorModal({ open: false });
                  // selectorCallback(item);
                }}
                title="Selecione"
                data={selectorModal.data}
                // unit={units_data == selectorData ? true : false}
                onClose={() => setSelectorModal({ open: false })}
              />
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data?.toPartner}
                    onChange={onCheckChange("toPartner")}
                    color="secondary"
                  />
                }
                label={<Text>Para Parceria</Text>}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={data?.active}
                    onChange={onCheckChange("active")}
                    color="secondary"
                  />
                }
                label={<Text>Ativo</Text>}
              />
            </div>
          </ContentContainer>
        </Box>
        <ContentContainer label="Produtos" fullWidth>
          {data?.products?.length > 0 ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 28,
                overflow: "hidden",
              }}
            >
              <Table aria-label="simple table">
                <TableBody>
                  {data.products.map((product, index) => {
                    return (
                      <TableRow key={product.product._id} sx={{ margin: 0 }}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            display: "flex",
                            flex: 1,
                            position: "relative",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              gap: 30,
                              flex: 1,
                            }}
                          >
                            <div
                              style={{
                                backgroundImage: `url(${product.product.urlImg})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                width: 120,
                                height: 80,
                                borderRadius: 8,
                              }}
                            />
                            <button
                              style={{
                                background: "#CB2027",
                                position: "absolute",
                                top: 5,
                                left: 5,
                                borderRadius: "50%",
                                padding: "0 2px",
                                border: "none",
                                cursor: "pointer",
                                boxShadow:
                                  "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
                              }}
                              onClick={() =>
                                handleRemoveProduct(product.product._id)
                              }
                            >
                              <IoClose
                                color="#fff"
                                size={18}
                                style={{ marginTop: 2 }}
                              />
                            </button>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                gap: 12,
                              }}
                            >
                              <Box>
                                <Link
                                  href={`/app/product/${product.product._id}`}
                                  target="_blank"
                                >
                                  <Text
                                    strong
                                    style={{
                                      fontSize: 18,
                                      maxWidth: 320,
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                    color="#444"
                                  >
                                    {product.product.name}
                                  </Text>
                                </Link>
                                <Text detail>
                                  Estoque: {product.product.estoque}
                                </Text>
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  gap: 10,
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    backgroundColor: Colors.paleDarkGreen,
                                    padding: `2px 18px`,
                                    borderRadius: 20,
                                    alignItems: "center",
                                  }}
                                >
                                  <Text detail strong color="#444">
                                    {formatter.currency(
                                      product.product.price * product.qtd
                                    )}
                                  </Text>
                                </Box>
                                <Box
                                  style={{
                                    display: "flex",
                                    backgroundColor: Colors.paleYellow,
                                    padding: `2px 16px`,
                                    borderRadius: 20,
                                    alignItems: "center",
                                    gap: 20,
                                  }}
                                >
                                  <Box
                                    onClick={() =>
                                      product?.qtd > 1
                                        ? handleChangeProductQtd({
                                            index,
                                            qtd: product?.qtd - 1,
                                          })
                                        : {}
                                    }
                                    style={{
                                      display: "flex",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text strong>{`-`}</Text>
                                  </Box>
                                  <Text strong>{product?.qtd}</Text>

                                  <Box
                                    onClick={() =>
                                      handleChangeProductQtd({
                                        index,
                                        qtd: product?.qtd + 1,
                                      })
                                    }
                                    style={{
                                      display: "flex",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text strong>{`+`}</Text>
                                  </Box>
                                </Box>
                              </Box>
                            </div>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Text style={{ fontSize: 14, color: "#999" }}>
                Nenhum produto selecionado.
              </Text>
            </Box>
          )}
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <TuimButton
              text={
                data?.products?.length > 0
                  ? "Editar produtos"
                  : "Selecionar produtos"
              }
              secondary
              onClick={getProdutosClickOpen}
            />
          </Box>

          <TableSelectModal
            itemsSelected={data?.products || []}
            items={list_products}
            order="asc"
            open={openModalProducts}
            refName="name"
            orderBy="name"
            page={0}
            rowsPerPage={10}
            selected={data?.products || []}
            title="Produtos"
            nameButton="Salvar e voltar"
            columns={columnsModalProducts}
            getRowsSelectedModal={getRowsSelectedModal}
            setItemSelected={setItemsSelected}
          />
        </ContentContainer>
      </Box>
    </>
  );
};

export default EditKit;
