import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { Text } from "../layout/components/text";
import { Box } from "@material-ui/core";

export const ListPrice = (props) => {
  const { pathname } = props.location;
  const _id = pathname.split("/").at(-1); //[arrayPath.length - 1];
  const token = localStorage.getItem("token");
  const headers = {
    headers: { Authorization: "bearer " + token },
  };

  const [data, setData] = useState({
    _id,
    name: "",
    discounts: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
  });

  const getDiscount = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/listDiscount/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        let { data } = response;
        setData(data);
      });
  };

  // const onDeletePress = async () => {
  //   const { _id } = state.data;

  //   axios
  //     .delete(`${process.env.REACT_APP_API_URL}/listDiscount/${_id}`, {
  //       headers: { Authorization: "bearer " + token },
  //     })
  //     .then((response) => {
  //       props.history.push(`/app/listDiscount/`);
  //     });
  // };

  const serializeData = () => {
    const formatedData = data;
    Object.entries(data.discounts).forEach(([key, value]) => {
      formatedData.discounts[key] = Number(value);
    });

    return formatedData;
  };

  const editDiscount = () => {
    const data = serializeData();

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/listDiscount/${data._id}`,
        data,
        headers
      )
      .then((response) => {
        if (response.status === 200) {
          alert("Tabela atualizada com sucesso!");
          props.history.push(`/app/listDiscount`);
          return;
        }

        alert("Houve um problema ao criar a tabela de desconto.");
      })
      .catch(() => {
        alert("Houve um problema ao criar a tabela de desconto.");
      });
  };

  const save = async () => {
    const data = serializeData();

    axios
      .post(`${process.env.REACT_APP_API_URL}/addDiscount/`, data, headers)
      .then((response) => {
        if (response.status === 201) {
          alert("Tabela criada com sucesso!");
          props.history.push(`/app/listDiscount`);
          return;
        }

        alert("Houve um problema ao criar a tabela de desconto.");
      })
      .catch(() => {
        alert("Houve um problema ao criar a tabela de desconto.");
      });
  };

  // const onChange = (e) => {
  //   setState({
  //     data: {
  //       ...state.data,
  //       [e.target.name]: e.target.value,
  //     },
  //   });
  // };

  // const removeDiscount = (index) => {
  //   let { discounts } = state.data;

  //   discounts.splice(index, 1);

  //   setState({
  //     data: {
  //       ...state.data,
  //       discounts,
  //     },
  //   });
  // };

  // const onChangeDiscount = (e) => {
  //   const { id, value, name } = e.target;

  //   let { data } = state;
  //   let { discounts } = data;

  //   discounts[id].percentual = Number(value);
  //   setState({ data: data });
  // };

  useEffect(() => {
    if (!data._id) return;
    getDiscount();
  }, []);

  return (
    <>
      <ContentHeader
        title={data.name || "Nova tabela de preços"}
        buttons={[
          {
            text: "Salvar",
            onClick: data._id ? editDiscount : save,
          },
        ]}
      />
      <ContentContainer>
        <TextField
          id="price-list-name"
          label="Nome"
          type="text"
          variant="outlined"
          value={data.name}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          style={{ marginTop: 4 }}
          fullWidth
        />
        <Box style={{ display: "flex", gap: 12, marginTop: 12 }}>
          {Object.entries(data.discounts).map(([key, value]) => (
            <Box key={`discount-listing-${key}`} style={{ width: "100%" }}>
              <Text strong>{String(key)} mês</Text>
              <TextField
                id="price-list-name"
                label={""}
                type="number"
                variant="standard"
                value={value}
                fullWidth
                onChange={(e) => {
                  setData({
                    ...data,
                    discounts: { ...data.discounts, [key]: e.target.value },
                  });
                }}
              />
            </Box>
          ))}
        </Box>
        <Text detail>
          Atenção! Para adicionar 15% ao preço de um produto, o valor precisa
          ser 15. Caso queira dar um desconto de 15%, o valor precisa ser
          negativo, ou seja, -15.
        </Text>
      </ContentContainer>
    </>
  );
};

const styles = {
  table: {
    minWidth: 700,
  },
  row: {
    cursor: "pointer",
  },
  fab: {
    margin: 0,
  },
  InputProps: {
    shrink: true,
    style: {
      // borderRadius: 40,
      paddingLeft: 10,
    },
  },
  inputProps: {
    shrink: true,
    style: {
      fontSize: 14,
      // borderRadius: 40,
      paddingLeft: 10,
      WebkitBoxShadow: `0 0 0 1000px #fff inset`,
    },
  },
  InputLabelProps: {
    shrink: true,
    style: {
      marginRight: 30,
      marginLeft: 4,
    },
  },
};

export default withStyles(styles)(ListPrice);
