import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { formatter } from "../../functions";

const ProductPrices = ({ prices = {} }) => {
  if (typeof prices !== "object" || Object.keys(prices).length === 0)
    return <p>Nenhum dado encontrado</p>;

  return (
    <Table size="medium">
      <TableHead>
        <TableRow>
          {["Período", "Porcentagem", "Preço final"].map((row) => (
            <TableCell key={row} align="center">
              {row}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(prices).map(([period, { percentage, price }]) => {
          return (
            <TableRow key={`${period}_listing_prices_${price}_${percentage}`}>
              <TableCell component="th" scope="row" align="center">
                {period}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {percentage}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {formatter.currency(price)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ProductPrices;
